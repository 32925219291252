import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { Preference } from '../../../models/Preference';

export type LoadMoreItemsCallback = (allItemsLoaded?: boolean) => void;
export type EnableInfiniteScrollCallback = () => void;

@Component({
    selector:    'app-select-tag',
    templateUrl: './select-tag.component.html',
    styleUrls:   ['./select-tag.component.scss']
})
export class SelectTagComponent {
    @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

    @Input() tags: Preference[] = [];
    @Input() showProperty = 'name';
    @Output() didTap = new EventEmitter<Preference>();
    @Output() didLoad = new EventEmitter<LoadMoreItemsCallback>();
    @Output() enableInfiniteScrollerCallback = new EventEmitter<EnableInfiniteScrollCallback>();

    constructor() {
    }

    loadMore(infiniteScroll) {
        this.didLoad.emit((allItemsLoaded) => {
            infiniteScroll.target.complete();
            if ( allItemsLoaded ) {
                infiniteScroll.target.disabled = true;
                this.enableInfiniteScrollerCallback.emit(() => {
                    infiniteScroll.target.disabled = false;
                });
                return;
            }
        });
    }

    didSelect(tag) {
        this.didTap.emit(tag);
    }
}
