import {Directive, NgModule} from '@angular/core';
import {AppearDirective} from './appear/appear.directive';



const directives = [
AppearDirective
];


@NgModule({
    declarations: [...directives],
    imports:      [],
    exports:      [...directives]
})

export class DirectivesModule {
}
