import { Subject } from 'rxjs';

export class NetworkMock {
    type: string;
    Connection = {
        UNKNOWN:  'unknown',
        ETHERNET: 'ethernet',
        WIFI:     'wifi',
        CELL_2G:  '2g',
        CELL_3G:  '3g',
        CELL_4G:  '4g',
        CELL:     'cellular',
        NONE:     'none'
    };

    private onConnectSubject = new Subject();
    private onDisconnectSubject = new Subject();

    constructor() {
        this.type = this.Connection.NONE;
        if ( navigator.onLine ) {
            this.type = this.Connection.WIFI;
        }

        window.addEventListener('online', () => {
            this.type = this.Connection.WIFI;
            this.onConnectSubject.next(this.type);
        });
        window.addEventListener('offline', () => {
            this.type = this.Connection.NONE;
            this.onDisconnectSubject.next();
        });
    }

    onConnect() {
        return this.onConnectSubject.asObservable();
    }

    onDisconnect() {
        return this.onDisconnectSubject.asObservable();
    }
}
