import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonContent, ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { DataCollection } from '../../../models/Collections/DataCollection';
import { Preference } from '../../../models/Preference';
import { ApiService } from '../../../providers/api/api.service';
import { ChatManagerService } from '../../../providers/chat-manager/chat-manager.service';
import { havePreferencesChanged } from '../../utility/havePreferencesChanged';
import { EnableInfiniteScrollCallback, LoadMoreItemsCallback } from '../select-tag/select-tag.component';

@Component({
    templateUrl: './select-tag-modal.component.html',
    styleUrls:   ['./select-tag-modal.component.scss']
})
export class SelectTagModalComponent implements OnInit {
    @Input() isEdit: boolean;
    totalItems = 0;
    toast: any;
    @ViewChild(IonContent, { static: false }) ionContent: IonContent;
    @Input() test: (params: any) => Observable<DataCollection<Preference>>;
    @Input() title: string;
    @Input() type: number;
    originalValue: Preference[];
    hasChanged = false;
    public data$: Observable<Preference[]>;
    private loadMoreItemsSubject = new Subject();
    private onSearchSubject = new Subject();
    private enableInfiniteScrollerSubject = new BehaviorSubject(null);
    private onTagSelected$: Subject<Preference[]> = new BehaviorSubject<Preference[]>([]);
    private STARTING_ITEMS_PER_PAGE = 40;
    itemsPerPage = this.STARTING_ITEMS_PER_PAGE;

    constructor(
        private allergySvc: ApiService,
        private router: Router,
        private chatManagerService: ChatManagerService,
        private toastCtrl: ToastController,
        private modalCtrl: ModalController
    ) {
        const tagsCollection = combineLatest([
                this.onSearchSubject.pipe(
                    tap(() => {
                        this.itemsPerPage = this.STARTING_ITEMS_PER_PAGE;
                        if ( this.enableInfiniteScrollerSubject.value ) {
                            this.enableInfiniteScrollerSubject.value();
                        }
                    }),
                    startWith(<string>null)
                ),
                this.loadMoreItemsSubject.pipe(startWith(<string>null))
            ]
        ).pipe(
            switchMap(([searchTerm]) => {
                const params = { itemsPerPage: this.itemsPerPage, page: 1 };
                if ( searchTerm ) {
                    params['filter'] = searchTerm;
                }
                return this.test(params);
            }),
        );

        this.data$ = combineLatest([
                tagsCollection,
            this.onTagSelected$,
            ]
        ).pipe(
            map(([collection, selectedTags]) => {
                let reduceTotal = 0;
                const toReturnTags = collection.data.filter(x => {
                    if ( selectedTags.find(selectedTag => selectedTag.id === x.id) ) {
                        reduceTotal += 1;
                        return false;
                    }
                    return true;
                });
                this.totalItems = collection.meta.filteredCount || collection.meta.totalCount;
                this.totalItems -= reduceTotal;
                console.log(`showing ${collection.meta.itemsPerPage * collection.meta.page}/${this.totalItems}`);
                return toReturnTags;
            })
        );
    }

    @Input() private _selectedTags: Preference[];

    get selectedTags(): Preference[] {
        return this._selectedTags;
    }

    set selectedTags(value: Preference[]) {
        this.originalValue = [...value];
        this._selectedTags = [...value];
        this.onTagSelected$.next(this._selectedTags);
    }

    changeSearchTerm(searchTerm) {
        this.onSearchSubject.next(searchTerm);
        this.ionContent.scrollToTop();
    }

    onDismiss() {
        this.modalCtrl.dismiss();
    }

    onSave() {
        this.modalCtrl.dismiss({ title: this.title, selectedTags: this._selectedTags, preferenceType: this.type });
    }

    ngOnInit() {
        console.log(this.isEdit);

    }

    loadMore(finishedLoadingCallback: LoadMoreItemsCallback) {
        this.itemsPerPage = (this.itemsPerPage + 20);
        this.loadMoreItemsSubject.next(this.itemsPerPage);
        finishedLoadingCallback(this.itemsPerPage >= this.totalItems);
    }

    onEnableInfiniteScroller($event: EnableInfiniteScrollCallback) {
        this.enableInfiniteScrollerSubject.next($event);
    }

    addSelectedItem(tag: Preference) {
        this._selectedTags.push(tag);
        this._selectedTags.sort((a, b) => a.name.localeCompare(b.name));
        this.onTagSelected$.next(this._selectedTags);
        this.hasChanged = havePreferencesChanged(this.originalValue, this._selectedTags);

    }

    removeTag(tag: Preference) {
        this._selectedTags = this._selectedTags.filter(x => x.id !== tag.id);
        this._selectedTags.sort((a, b) => a.name.localeCompare(b.name));
        this.onTagSelected$.next(this._selectedTags);
        this.hasChanged = havePreferencesChanged(this.originalValue, this._selectedTags);
    }

}
