import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { PreferencesService } from '../../providers/preferences/preferences.service';

@Pipe({
    name: 'preferences'
})
export class PreferencesPipe implements PipeTransform {

    constructor(private preferencesService: PreferencesService) {
    }

    transform(value: [], args?: any): any {

        console.log(value);

        this.preferencesService.getAllergies().pipe(
            map(allergies => allergies.filter(allergy => allergy.id == 4))).subscribe(data => console.log(data));

        switch (args) {

            case 'allergyIds': {

                break;
            }
            case 'dietaryIds': {

                break;
            }
            case 'illnnessIds': {

                break;
            }
        }


    }

}
