import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';


declare var google;


@Component({
    selector:    'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls:   ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {

    @ViewChild('map', { static: false }) mapElement: ElementRef;

    map: any;

    constructor() {
    }

    ngOnInit() {

        this.map = new google.maps.Map(
            this.mapElement.nativeElement,
            {
                center: { lat: 50.8223749, lng: -0.1443448 },
                zoom:   18
            }
        );
    }

}
