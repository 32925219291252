import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-group-preferences-list',
  templateUrl: './group-preferences-list.component.html',
  styleUrls: ['./group-preferences-list.component.scss'],
})
export class GroupPreferencesListComponent implements OnInit {

  @Input() title: string;
  @Input() preferences: any[];

  constructor() { }

  ngOnInit() {}

}
