import { Component, OnInit } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { ContactManagerService, NCloodUser } from '../../../providers/contact-manager/contact-manager.service';
import { InvitesService } from '../../../providers/invites/invites.service';
import { ConnectionStatus, NetworkService } from '../../../providers/network/network.service';

@Component({
  selector: 'app-invite-friend',
  templateUrl: './invite-friend.component.html',
  styleUrls: ['./invite-friend.component.scss'],
})
export class InviteFriendComponent implements OnInit {
  terms: any;
  phones: any = {};
  uninvitedContacts$: Observable<NCloodUser[]>;
  invitedContacts$: Observable<NCloodUser[]>;
  category: string;

  loader: HTMLIonLoadingElement;

  constructor(
    private modalCtrl: ModalController,
    private contactManager: ContactManagerService,
    private socialSharing: SocialSharing,
    private alertCtrl: AlertController,
    private loadCtrl: LoadingController,
    private inviteSvc: InvitesService,
    private networkSvc: NetworkService,
    private toastCtrl: ToastController
  ) {
    this.category = 'uninvited';
    const contacts$ = this.contactManager.getNotNCloodContacts()
      .pipe(
        map((listContacts) => listContacts.map((item) => item.contact)),
        tap(contacts => {
          this.phones = contacts.reduce((acc, contact) => {
            acc[contact.number] = false;
            return acc;
          }, {});
        }),
        shareReplay(1)
      );
    this.uninvitedContacts$ = combineLatest([
      contacts$,
      this.inviteSvc.getInvites(),
    ]
    ).pipe(
      map(([contacts, invitations]) => {
        return contacts
          .reduce((toReturnContacts, contact) => {

            const found = invitations.find(x => x.phone === contact.number);
            if (!found) {
              toReturnContacts.push(contact);
            }
            return toReturnContacts;
          }, []);
      }),
      tap(() => {
        this.loadCtrl.getTop().then(loader => {
          if (loader) {
            this.loadCtrl.dismiss();
          }
        });
      })
    );
    this.invitedContacts$ = combineLatest([
      contacts$,
      this.inviteSvc.getInvites(),
    ]
    )
      .pipe(
        map(([contacts, invitations]) => {
          return contacts
            .reduce((toReturnContacts, contact) => {
              const found = invitations.find(x => x.phone === contact.number);
              if (found) {
                toReturnContacts.push({ status: found.status, displayName: contact.displayName });
              }
              return toReturnContacts;
            }, []);
        }),
        tap(() => {
          this.loadCtrl.getTop().then(loader => {
            if (loader) {
              this.loadCtrl.dismiss();
            }
          });
        })
      );

  }

  ngOnInit() {

  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  checkLength() {
    return Object.keys(this.phones).filter(k => this.phones[k]).length;
  }

  async presentAlertPrompt() {
    const alert = await this.alertCtrl.create({
      header: 'Invite Message',
      message: '"Hi, I’m using nClood to meet friends, communicate dietary or health issues and share expenses\n' +
        'Download it at Android Store or Apple Store."',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Confirm Invite',
          handler: () => {
            this.sendInvitation();
          }
        }
      ]
    });

    await alert.present();
  }

  sendInvitation() {
    if (this.phones.length > 1) {
      this.loader.message = 'Sending Invites...';
    } else {
      this.loader.message = 'Sending Invite...';
    }
    this.loader.present().then(() => {
      if (this.networkSvc.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
        this.loader.dismiss();
        this.alertCtrl.create({
          header: 'Unable to send invitation',
          message: 'Please make sure you have internet connection',
          buttons: ['Try again']
        }).then(alert => {
          alert.present();
        });

        return;
      }

      forkJoin(
        Object
          .keys(this.phones)
          .filter(k => this.phones[k])
          .map(phone => this.inviteSvc.sendInvite(phone, ''))
      )
        .subscribe(result => {
          this.loader.dismiss().then(() => {
            this.modalCtrl.dismiss().then(() => {
              setTimeout(() => {
                this.toastCtrl.create({
                  message: 'Invitation was sent successfully',
                  duration: 3000,
                  color: 'primary'
                }).then(toast => {
                  toast.present();
                });
              }, 1000);
            });
          });

        });
    });


  }

  private inviteRequest(phone, message) {

  }

  async presentLoading(message: string) {
    this.loader = await this.loadCtrl.create({
      message: message,
      spinner: 'bubbles'
    });

    await this.loader.present();
  }

  syncContacts() {
    this.presentLoading('Syncing Contacts...').then(() => {
      this.contactManager.fetchContactUpdates().pipe(take(1)).subscribe(() => {
        this.loadCtrl.dismiss();
      }, (error) => {
        this.loadCtrl.dismiss();
        this.alertCtrl.create({
          header: 'Failed to sync contacts',
          buttons: [
            {
              text: 'Try again',
              handler: () => {
                this.syncContacts();
              }
            },
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
              }
            }
          ]
        }).then(alert => {
          alert.present();

        });
        return;
      });
    });

  }

  segmentChanged($event) {
    this.presentLoading('Loading...');
  }
}
