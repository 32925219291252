import { Pipe, PipeTransform } from '@angular/core';
import { Chat } from '../../../models/Chat';

@Pipe({
    name: 'chat'
})
export class ChatPipe implements PipeTransform {

    transform(chat: Chat, currentUserId?: number): Chat {
        let c: Chat;
        if ( !chat ) {
            return;
        }
        if ( chat.group === false ) {
            chat.picture = chat.participants.filter((users) => users.id !== currentUserId).map((u) => u.user.profilePicture).toString();
            chat.title = chat.participants.filter((users) => users.id !== currentUserId)
                .map((u) => u.user.profileName)
                .toString();
            c = chat;
        }
        return c;
    }


}
