import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './providers/guards/auth-guard.service';

const routes: Routes = [
    { path: '', redirectTo: 'tabs', pathMatch: 'full' },
    { path: 'signin', loadChildren: './pages/signin/signin.module#SigninPageModule' },
    { path: 'signup', loadChildren: './pages/signup/signup.module#SignupPageModule' },
    { path: 'tabs', loadChildren: './pages/tabs/tabs.module#TabsPageModule', canActivate: [AuthGuardService], canLoad: [AuthGuardService] },
    { path: 'accounts', loadChildren: './pages/accounts/accounts.module#AccountsPageModule', canActivate: [AuthGuardService] },
    { path: 'allergy', loadChildren: './pages/allergy/allergy.module#AllergyPageModule', canActivate: [AuthGuardService] },
    {
        path:         'sync-contacts',
        loadChildren: './pages/sync-contacts/sync-contacts.module#SyncContactsModule',
        canActivate:  [AuthGuardService]
    },
    { path: 'chats', loadChildren: './pages/chats/chats.module#ChatsPageModule', canActivate: [AuthGuardService] },
    { path: 'friends', loadChildren: './pages/friends/friends.module#FriendsPageModule', canActivate: [AuthGuardService] },
    {
        path:         'user-profile',
        loadChildren: './pages/user-profile/user-profile.module#UserProfilePageModule',
        canActivate:  [AuthGuardService]
    },
    { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule', canActivate: [AuthGuardService] },
    {
        path:     'chat/:chatId',
        children: [
            { path: '', loadChildren: './pages/chat/chat.module#ChatPageModule', canActivate: [AuthGuardService] },

        ]
    },
    { path: 'dietary', loadChildren: './pages/dietary/dietary.module#DietaryPageModule', canActivate: [AuthGuardService] },
    { path: 'physical', loadChildren: './pages/physical/physical.module#PhysicalPageModule', canActivate: [AuthGuardService] },
    { path: 'onboarding', loadChildren: './pages/onboarding/onboarding.module#OnboardingPageModule', canActivate: [AuthGuardService] },
    {
        path:         'new-group-modal',
        loadChildren: './shared/components/new-group-modal/new-group-modal.module#NewGroupModalPageModule',
        canActivate:  [AuthGuardService]
    },
    {
        path:         'pending-requests',
        loadChildren: './pages/pending-requests/pending-requests.module#PendingRequestsComponentModule',
        canActivate:  [AuthGuardService]
    },
    { path: 'amount', loadChildren: './pages/amount/amount.module#AmountPageModule' },
    {
        path:         'confirm-transfer',
        loadChildren: './pages/confirm-transfer/confirm-transfer.module#ConfirmTransferPageModule',
        canActivate:  [AuthGuardService]
    },
    {
        path:         'select-charity',
        loadChildren: './pages/donate/select-charity/select-charity.module#SelectCharityPageModule',
        canActivate:  [AuthGuardService]
    },
    {
        path:         'terms-and-conditions',
        loadChildren: './terms-and-conditions/terms-and-conditions.module#TermsAndConditionsPageModule',
        canActivate:  [AuthGuardService]
    },
    {
        path:         'agenda',
        loadChildren: './pages/agenda/agenda.module#AgendaComponentModule',
        canActivate:  [AuthGuardService]
    },
    { path: 'coming-soon', loadChildren: './coming-soon/coming-soon.module#ComingSoonPageModule' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            { preloadingStrategy: PreloadAllModules }
        ),
        ReactiveFormsModule,
        FormsModule],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
