import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searcher'
})
export class SearchPipeArray implements PipeTransform {
    transform(items: any[], terms: string, compareFunction: (item: any) => boolean): any[] {

        if ( !items ) {
            return [];
        }
        if ( !terms ) {
            return items;
        }
        terms = terms.toLowerCase();

        return items.filter(compareFunction);
    };
}
