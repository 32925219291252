import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector:    'app-preferences-list',
    templateUrl: './preferences-list.component.html',
    styleUrls:   ['./preferences-list.component.scss']
})
export class PreferencesListComponent implements OnInit {

    @Input() title: string;
    @Input() preferences: any[];
    @Input() editable: boolean;

    constructor() {
        console.log(this.preferences);
    }

    ngOnInit() {
    }

}
