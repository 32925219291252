import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export interface LoggedUser {
    ncId: string;
    profileName: string;
    phone: string;
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private static _tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('token'));
    public static decodedToken$: Observable<any> = TokenInterceptor._tokenSubject.pipe(
        filter(token => !!token),
        map(token => {
            const helper = new JwtHelperService();
            return helper.decodeToken(token).user;
        })
    );

    constructor(private navCtrl: NavController) {
    }

    static setToken(token) {
        localStorage.setItem('token', token);
        TokenInterceptor._tokenSubject.next(token);
    }

    static clearToken() {
        localStorage.removeItem('token');
        TokenInterceptor._tokenSubject.next(null);
    }

    static getToken(): string {
        return localStorage.getItem('token');
    }

    static getCurrentUser(): LoggedUser {
        const token = this.getToken();

        if ( !token ) {
            return null;
        }

        const helper = new JwtHelperService();
        return helper.decodeToken(token).user;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // check if we actaully have a token  
        const token = TokenInterceptor.getToken();
        if ( token ) {
            req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        }
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if ( event instanceof HttpResponse ) {
                    // do stuff with the response ..if necessary 
                }
            }, (err: any) => {
                if ( err instanceof HttpErrorResponse ) {
                    if ( err.status === 401 ) {
                        TokenInterceptor.clearToken();
                        this.navCtrl.navigateRoot('/signin');

                        // this.router.navigate(['/login']);
                    }
                    if ( err.status === 500 ) {
                        console.log('some error from the server');
                    }
                }
            }));
    }
} 
