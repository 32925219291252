import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'containsObject'
})
export class ContainsObjectPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        let i;
        for ( i = 0; i < args.length; i++ ) {
            if ( args[i] === value ) {
                return true;
            }
        }
        return false;
    }
}
