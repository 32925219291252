import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import moment from 'moment';
import { forkJoin, of, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { DataStorageService } from '../data-storage/data-storage.service';

@Injectable({ providedIn: 'root' })
export class MeetingService {
    onMeetingChanged$ = new Subject();

    constructor(private dataStorageSvc: DataStorageService, private apiSvc: ApiService, private network: Network) {

    }

    getMeetings() {
        return this.onMeetingChanged$.pipe(
            startWith(<string>null),
            switchMap(() => {
                const defaultObservable = fromPromise(this.dataStorageSvc.getMeetings());
                if ( this.network.type !== this.network.Connection.NONE ) {
                    return defaultObservable.pipe(
                        switchMap(storedMeetings => {
                            return this.apiSvc.getMeetings().pipe(
                                switchMap(meetings => {
                                    return forkJoin(meetings.map(meeting => {
                                        if ( !storedMeetings.find(storedMeeting => storedMeeting.id === meeting.id) ) {
                                            return of(meeting);
                                        }
                                        return fromPromise(
                                            this.dataStorageSvc.createMeeting(
                                                meeting.id,
                                                meeting.groupId,
                                                JSON.stringify(meeting.participants),
                                                meeting.description,
                                                meeting.date,
                                                meeting.place
                                            )
                                        );
                                    }));
                                }),
                                map(meetings => {
                                    return meetings.filter(meeting => moment(meeting.date).isSameOrAfter(moment()));
                                })
                            );
                        })
                    );
                }
                return defaultObservable;
                // we have internet ...pull info from internet and store the latest version
            })
        );
    }

    meetingCreated() {
        this.onMeetingChanged$.next();
    }
}
