import { SQLiteDatabaseConfig } from '@ionic-native/sqlite/ngx';

declare var SQL;

export class SQLiteObject {
    _objectInstance: any;

    constructor(_objectInstance: any, private debug?) {
        this._objectInstance = _objectInstance;
    }

    executeSql(statement: string, params: any): Promise<any> {

        return new Promise((resolve, reject) => {
            try {
                if ( this.debug ) {
                    console.log(statement, params);
                }
                const st = this._objectInstance.prepare(statement, params);
                const rows: Array<any> = [];
                while (st.step()) {
                    const row = st.getAsObject();
                    rows.push(row);
                }
                const payload = {
                    rows:         {
                        item:   function (i) {
                            return rows[i];
                        },
                        length: rows.length
                    },
                    rowsAffected: this._objectInstance.getRowsModified() || 0,
                    insertId:     this._objectInstance.insertId || void 0
                };
                // save database after each sql query
                const arr: ArrayBuffer = this._objectInstance.export();
                localStorage.setItem('database', String(arr));
                resolve(payload);
            } catch (e) {
                reject(e);
            }
        });
    }

    sqlBatch(statements: string[], params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const rows: Array<any> = [];
                for ( const statement of statements ) {
                    if ( this.debug ) {
                        console.log(statement);
                    }
                    const st = this._objectInstance.prepare(statement, params);
                    while (st.step()) {
                        const row = st.getAsObject();
                        rows.push(row);
                    }
                }
                const payload = {
                    rows:         {
                        item:   function (i) {
                            return rows[i];
                        },
                        length: rows.length
                    },
                    rowsAffected: this._objectInstance.getRowsModified(),
                    insertId:     this._objectInstance.insertId || void 0
                };
                // save database after each sql query

                const arr: ArrayBuffer = this._objectInstance.export();
                localStorage.setItem('database', String(arr));
                resolve(payload);
            } catch (e) {
                reject(e);
            }
        });
    }
}

/*
  Implemented using edited code from actual cordova plugin
*/
export class SQLitePorterMock {
    /**
     * Trims leading and trailing whitespace from a string
     * @param {string} str - untrimmed string
     * @returns {string} trimmed string
     */


    trimWhitespace(str) {
        return str.replace(/^\s+/, '').replace(/\s+$/, '');
    }

    importSqlToDb(db, sql, opts = {}) {
        try {
            const statementRegEx = /(?!\s|;|$)(?:[^;"']*(?:"(?:\\.|[^\\"])*"|'(?:\\.|[^\\'])*')?)*/g;
            let statements = sql
                .replace(/(?:\/\*(?:[\s\S]*?)\*\/)|(?:([\s;])+\/\/(?:.*)$)/gm, '') // strip out comments
                .match(statementRegEx);

            if ( statements === null || (Array.isArray && !Array.isArray(statements)) ) {
                statements = [];
            }

            // Strip empty statements
            for ( let i = 0; i < statements.length; i++ ) {
                if ( !statements[i] ) {
                    delete statements[i];
                }
            }
            return db.sqlBatch(statements);
        } catch (e) {
            console.error(e.message);
        }
    }
}

export class SQLiteMock {

    public create(config: SQLiteDatabaseConfig, debug: false): Promise<SQLiteObject> {
        let db;
        const storeddb = localStorage.getItem('database');

        if ( storeddb ) {
            const arr = storeddb.split(',');
            db = new SQL.Database(arr);
        } else {
            db = new SQL.Database();
        }

        return new Promise((resolve, reject) => {
            resolve(new SQLiteObject(db));
        });
    }
}
