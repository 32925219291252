import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/operators';
import { TokenInterceptor } from '../../interceptors/token.interceptor';
import { Chat } from '../../models/Chat';
import { ChatCollection } from '../../models/Collections/ChatCollection';
import { Preference } from '../../models/Preference';
import { ApiService } from '../api/api.service';
import { CommunicationManagerService, UserMetaData } from '../communication-manager/communication-manager.service';
import { DataStorageService } from '../data-storage/data-storage.service';
import { NetworkService } from '../network/network.service';
import { OfflineManagerService } from '../offlineManager/offline-manager.service';


@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private  http: HttpClient,
        private networkService: NetworkService,
        private offlineManager: OfflineManagerService,
        private communicationManager: CommunicationManagerService,
        private apiSvc: ApiService,
        private dataSvc: DataStorageService
    ) {
    }

    getProfile(): Observable<UserMetaData> {
        const currentUser = TokenInterceptor.getCurrentUser();
        return fromPromise(this.dataSvc.getUserMetadata(currentUser.ncId));
    }

    getUserAllergy(): Observable<Preference[]> {
        return this.getPreference('allergies');
    }

    removeUserAllergy(id) {
        return this.http.delete(`${this.apiSvc.APIURL}/me/allergies/${id}`);
    }

    getUserDietary(): Observable<Preference[]> {
        return this.getPreference('dietary-preferences');

    }

    getUserPhysicalInability(): Observable<Preference[]> {
        return this.getPreference('physical-and-mental-symptom');

    }

    getUserTemporaryIllness(): Observable<Preference[]> {
        return this.getPreference('temporary-illnesses');
    }


    private getRawChats(): Observable<Chat[]> {
        return this.http.get<ChatCollection>(`${this.apiSvc.APIURL}/me/chats`).pipe(
            map((meta) => meta.data)
        );
    }

    private getPreference(url) {
        return this.http.get<{ data: Preference[] }>(`${this.apiSvc.APIURL}/me/${url}`).pipe(
            map((i) => i.data)
        );
    }

}

