import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { SQLiteMessageCollection } from '../../models/Collections/SQLiteMessageCollection';
import { Invitation } from '../../models/Invitation';
import { Meeting } from '../api/api.service';
import { GroupChatMetaData, UserMetaData } from '../communication-manager/communication-manager.service';

export enum SQLiteMessageStatus {
    Received = 0,
    WaitingServer = 4,
    DestinationReceived = 5,
    ControlMessage = 6
}

export enum SQLiteControlMessage {
    GroupCreation = 1,
    GroupTitleUpdate = 2,
    GroupPictureUpdate = 3,
    GroupJoin = 4,
    GroupLeave = 5,
    GroupLocationUpdate = 6,
    GroupDateUpdate = 7,
    AllergyUpdate = 8,
    DietaryUpdate = 9,
    PhysicalAndMentalUpdate = 10,
    TemporaryIllnessUpdate = 11,
    ProfilePictureUpdate = 12,
    GroupPromoteUser = 13,
    GroupDemoteUser = 14,
    ProfilePictureUpload = 15,
    MeetingCreated = 16,
    GreetingEnvelope = 17,
    GreetingImage = 18,
    GreetingText = 19,
    GreetingGift = 20,
}

export enum SQLiteMessageKeyFromMe {
    Incoming = 0,
    Outgoing = 1
}

export interface SQLiteChat {
    keyRemoteNcId: string;
    messageTableId: string;
}

export interface SQLiteChatWithMessage extends SQLiteChat {
    lastMessage: SQLiteMessage;
}

export interface SQLiteMessage {
    id?: number;
    keyRemoteNcId: string;
    keyFromMe: SQLiteMessageKeyFromMe;
    keyId?: string;
    status: SQLiteMessageStatus;
    data: string;
    timestamp?: number;
    mediaURL?: string;
    controlMessage?: SQLiteControlMessage;
    mediaName?: string;
    date?: string;
    latitude?: number;
    longitude?: number;
    remoteResource?: string;
    receivedTimestamp?: number;
    receiptServerTimestamp?: number;
    receiptDeviceTimestamp?: number;
    imageThumbnail?: string;
    uploadedMedia?: boolean;
    tryingToSend?: string;
}

export interface SystemMessage {
    id: number;
    message: string;
    createdAt: string;
    extras: any;
}

export interface SQLiteContact {
    ncId: string;
    phoneId: string;
    status: string;
    number: string;
    displayName: string;
    unseenMsgCount: number;
}

export interface SQLiteChatListItem extends SQLiteChat {
    lastMessage: SQLiteMessage;
}

@Injectable({
    providedIn: 'root'
})
export class DataStorageService {

    private dbPromise: Promise<SQLiteObject>;

    constructor(private platform: Platform, private sqlite: SQLite) {
        // we need to make sure the DB is created
        this.dbPromise = this.platform.ready()
            .then(() => {
                return sqlite.create({ name: 'NClood.db', location: 'default' });
            })
            .then(db => {
                // first we have to test if the DB does exist or not
                // language=SQLITE-SQL
                return db.executeSql(
                    `SELECT name FROM sqlite_master WHERE type="table" AND name="table_version"`, []
                )
                    .then(
                        (rs) => {
                            let promise = Promise.resolve();
                            if ( !rs.rows.length ) {
                                // we are looking at an empty DB..we have to create all the tables
                                promise = promise.then(() => this.createTables(db));
                            }
                            return promise.then(() => this.updateTables(db));
                        },
                        error => {
                            console.log(error);
                        }
                    );

            });
    }

    getNextMessageId() {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                return db.executeSql('select nextId + 1 as nextId  from  db_sequence where name = "message"', []);
            }
        )
            .then(rs => {
                if ( !rs.rows.length ) {
                    return 0;
                }

                return rs.rows.item(0).nextId;
            });
    }

    createMessage(givenMessage: SQLiteMessage): Promise<SQLiteMessage> {
        return this.dbPromise.then(db => {
            if ( !givenMessage.timestamp ) {
                givenMessage.timestamp = Date.now();
            }
            return db.executeSql(
                `insert into messages (
                id,
                keyRemoteNcId,
                keyFromMe,
                keyId,
                status,
                data,
                mediaURL,
                controlMessage,
                mediaName,
                imageThumbnail,
                latitude,
                longitude,
                date,
                remoteResource,
                timestamp,
                receivedTimestamp,
                receiptServerTimestamp,
                receiptDeviceTimestamp
                ) values (
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?,
                ?)`
                , [
                    givenMessage.id,
                    givenMessage.keyRemoteNcId,
                    givenMessage.keyFromMe,
                    givenMessage.keyId,
                    givenMessage.status,
                    givenMessage.data,
                    givenMessage.mediaURL,
                    givenMessage.controlMessage,
                    givenMessage.mediaName,
                    givenMessage.imageThumbnail,
                    givenMessage.latitude,
                    givenMessage.longitude,
                    givenMessage.date,
                    givenMessage.remoteResource,
                    givenMessage.timestamp,
                    givenMessage.receivedTimestamp,
                    givenMessage.receiptServerTimestamp,
                    givenMessage.receiptDeviceTimestamp,
                ])
                .then(() => {
                    return this.getMessage(givenMessage.keyId);
                })
                .then(newMessage => {
                    return this.incrementSequence('message')
                        .then(() => {
                            return newMessage;
                        });
                });
        });

    }

    updateMessage(givenMessage: SQLiteMessage) {
        return this.dbPromise.then(db => {
            return db.executeSql(
                `update messages set
                status = ?,
                receivedTimestamp = ?,
                receiptServerTimestamp = ?,
                receiptDeviceTimestamp = ?,
                mediaURL  = ?,
                imageThumbnail = ?,
                uploadedMedia = ?
                where keyId = ?`,
                [
                    givenMessage.status,
                    givenMessage.receivedTimestamp,
                    givenMessage.receiptServerTimestamp,
                    givenMessage.receiptDeviceTimestamp,
                    givenMessage.mediaURL,
                    givenMessage.imageThumbnail,
                    givenMessage.uploadedMedia,
                    givenMessage.keyId,
                ]
            );
        });
    }

    deleteMessage(keyId: string) {
        return this.dbPromise.then(db => {
            return db.executeSql(
                'delete from messages where id = ?',
                [
                    keyId
                ]
            );
        });
    }

    getMessagesForChat(remoteId, itemsPerPage = 20, page = 1): Promise<SQLiteMessageCollection> {
        return this.dbPromise
            .then(db => {
                return Promise.all(
                    [
                        db.executeSql(
                            'select * from messages where keyRemoteNcId = ? order by timestamp desc limit ? offset ?',
                            [
                                remoteId,
                                itemsPerPage,
                                (page - 1) * itemsPerPage
                            ]
                        ).then(this.resultToArray),
                        db.executeSql(
                            'select count(*) as count from messages where keyRemoteNcId = ?',
                            [
                                remoteId
                            ]
                        ).then(resultSet => {
                            if ( !resultSet.rows.length ) {
                                return 0;
                            }

                            return resultSet.rows.item(0).count;
                        })
                    ]);
            })
            .then(([data, totalCount]) => {
                return {
                    data,
                    meta: {
                        totalCount:    totalCount,
                        filteredCount: totalCount,
                        page:          page,
                        itemsPerPage:  itemsPerPage
                    }
                };
            });
    }

    createContact(givenContact: SQLiteContact) {

        return this.dbPromise.then(db => {
                // language=SQLITE-SQL
                return db.executeSql(
                    `insert into contacts (
    ncId,
    phoneId,
    status,
    number,
    displayName,
    unseenMsgCount
    )
values (?, ?, ?, ?, ?, ?)`
                    , [
                        givenContact.ncId,
                        givenContact.phoneId,
                        givenContact.status,
                        givenContact.number,
                        givenContact.displayName,
                        givenContact.unseenMsgCount,
                    ]);
            }
        );
    }

    updateContact(contact: SQLiteContact) {
        return this.dbPromise.then(db => {
            return db.executeSql(
                `update contacts set
status = ?,
number = ?,
displayName = ?,
unseenMsgCount = ?
where ncId = ?`,
                [
                    contact.status,
                    contact.number,
                    contact.displayName,
                    contact.unseenMsgCount,
                    contact.ncId,
                ]
            );
        });
    }

    deleteContact(contactID) {
        return this.dbPromise.then(db => {
            return db.executeSql(
                'delete from contacts where ncId = ?',
                [
                    contactID
                ]
            );
        });
    }

    getContacts(params: { searchTerm?: string, limit?: number, offset?: number, notEnrolled?: boolean } = null): Promise<SQLiteContact[]> {
        let searchTerm;
        let limit;
        let offset;
        let notEnrolled = null;
        if ( params ) {
            searchTerm = params.searchTerm;
            limit = params.limit;
            offset = params.offset;
            notEnrolled = params.notEnrolled;
        }
        return this.dbPromise
            .then(db => {
                // language=SQLITE-SQL
                let query = `select * from contacts `;
                const whereStatements = [];
                const queryParams = [];
                if ( searchTerm ) {
                    whereStatements.push(' displayName like ? ');
                    queryParams.push(`%${searchTerm}%`);
                }

                if ( notEnrolled === true ) {
                    whereStatements.push(' status is null ');
                } else if ( notEnrolled === false ) {
                    whereStatements.push(' status is not null ');
                }

                if ( whereStatements.length ) {
                    query += ` where ${whereStatements.join(' and ')}`;
                }

                query += ' order by displayName asc';

                if ( limit ) {
                    if ( !offset ) {
                        offset = 0;
                    }
                    query += ' limit ? offset ? ';
                    queryParams.push(limit);
                    queryParams.push(offset);
                }
                return db.executeSql(query, queryParams);
            })
            .then(this.resultToArray);
    }

    getContact(ncId: any): Promise<SQLiteContact> {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                const query = `select * from contacts where ncId = ?`;

                return db.executeSql(
                    query,
                    [ncId]
                );
            })
            .then(this.resultToSingle);
    }

    getChat(ncId: any): Promise<SQLiteChat> {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                const query = `select * from chat_list where keyRemoteNcId = ?`;

                return db.executeSql(
                    query,
                    [ncId]
                )
                    .then(this.resultToSingle);
            }
        );
    }

    public getMessage(keyId: string): Promise<SQLiteMessage> {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                const query = `select * from messages where keyId = ?`;

                return db.executeSql(
                    query,
                    [keyId]
                )
                    .then(this.resultToSingle);
            }
        );
    }

    createChat(keyRemoteNcId: string, messageTableId: string) {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                const query = `insert into chat_list(keyRemoteNcId, messageTableId) values (?, ?) `;

                return db.executeSql(
                    query,
                    [keyRemoteNcId, messageTableId]
                )
                    .then(this.resultToSingle);
            }
        );
    }

    updateChat(keyRemoteNcId: string, messageTableId: string) {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                const query = `update chat_list set messageTableId = ? where keyRemoteNcId = ? `;

                return db.executeSql(
                    query,
                    [messageTableId, keyRemoteNcId]
                )
                    .then(this.resultToSingle);
            });
    }


    getMessagesPendingToSend(): Promise<SQLiteMessage[]> {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                return db.executeSql(
                    // tslint:disable-onDismiss-line:max-line-length
                    `select * from messages where keyFromMe = ? and receiptServerTimestamp is null
                    and (tryingToSend is null or tryingToSend < time() )  order by keyId asc `,
                    [
                        SQLiteMessageKeyFromMe.Outgoing
                    ]
                )
                    .then(this.resultToArray)
                    .then(messages => {
                        return this.flagMessagesAsTryingToSend(messages)
                            .then(() => {
                                return messages;
                            });
                    });
            }
        );
    }

    public stopTryingToSendMessage(message: SQLiteMessage) {
        return this.dbPromise
            .then(db => {
                if ( !message ) {
                    return true;
                }
                return db.executeSql(`update messages set tryingToSend = null where keyId = ?`, [message.keyId]);
            });
    }

    createGroupChatMetadata(messageToReceive: GroupChatMetaData) {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                // tslint:disable-onDismiss-line:max-line-length
                const query = `insert into
                groupChatMetadata ( ncId,locationLatitude,locationLongitude,"date",title,photoURL, photoThumbnail,owner_id,participants)
                values ( ?,?,?,?,?,?,?,?,?)`;
                return db.executeSql(query, [
                    messageToReceive.ncId,
                    messageToReceive.locationLatitude,
                    messageToReceive.locationLongitude,
                    messageToReceive.date,
                    messageToReceive.title,
                    messageToReceive.photoURL,
                    messageToReceive.photoThumbnail,
                    messageToReceive.owner_id,
                    JSON.stringify(messageToReceive.participants),
                ]);
            }
        );
    }

    getChats(): Promise<SQLiteChatWithMessage[]> {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                return db.executeSql('select * from chat_list', [])
                    .then(this.resultToArray)
                    .then(listOfChats => {
                        return Promise.all(
                            listOfChats.map(chatItem => {
                                return this.getMessage(chatItem.messageTableId)
                                    .then(
                                        lastMessage => {
                                            chatItem.lastMessage = lastMessage;
                                            return chatItem;
                                        }
                                    )
                                    .then(prev => {
                                        if ( !prev.keyRemoteNcId || prev.keyRemoteNcId.indexOf('-') === -1 ) {
                                            return prev;
                                        }
                                        return this.getGroupChatMetadata(chatItem.keyRemoteNcId)
                                            .then(metadata => {
                                                return {
                                                    ...chatItem,
                                                    metadata
                                                };
                                            });
                                    });
                            })
                        );
                    })
                    .then(result => {
                        return result;
                    });
            }
        );
    }

    getUserMetadata(ncId) {
        return this.dbPromise
            .then(db => {
                return db.executeSql('select * from userMetadata where ncId = ?', [ncId]);
            })
            .then(this.resultToSingle)
            .then(sqlUserMetadata => {
                if ( !sqlUserMetadata ) {
                    return null;
                }
                const userMetadata: UserMetaData = {
                    ...sqlUserMetadata,
                    allergies:                 JSON.parse(sqlUserMetadata.allergies),
                    dietaryPreferences:        JSON.parse(sqlUserMetadata.dietaryPreferences),
                    physicalAndMentalSymptoms: JSON.parse(sqlUserMetadata.physicalAndMentalSymptoms),
                    temporaryIllnesses:        JSON.parse(sqlUserMetadata.temporaryIllnesses)
                };
                return userMetadata;
            });
    }

    getGroupChatMetadata(groupChatId: any): Promise<GroupChatMetaData> {
        return this.dbPromise.then(
            db => {
                const query = 'select * from groupChatMetadata where ncId = ?';
                return db.executeSql(
                    query,
                    [groupChatId]
                )
                    .then(this.resultToSingle)
                    .then(x => {
                        if ( !x ) {
                            return;
                        }
                        x.participants = JSON.parse(x.participants);
                        return x;
                    });
            }
        );
    }

    updateGroupChatMetadata(metadata: GroupChatMetaData) {
        return this.dbPromise.then(db => {
            return db.executeSql(
                `update groupChatMetadata set
                locationLatitude = ?,
                locationLongitude = ?,
                date = ?,
                title = ?,
                photoURL = ?,
                photoThumbnail = ?,
                createdAt = ?,
                owner_id = ?,
                participants = ?
                where ncId = ?`,
                [
                    metadata.locationLatitude,
                    metadata.locationLongitude,
                    metadata.date,
                    metadata.title,
                    metadata.photoURL,
                    metadata.photoThumbnail,
                    metadata.createdAt,
                    metadata.owner_id,
                    JSON.stringify(metadata.participants),
                    metadata.ncId,
                ]
            );
        });
    }

    updateUserMetadata(metadata: UserMetaData) {
        return this.dbPromise.then(db => {
            return db.executeSql(
                // tslint:disable-next-line:max-line-length
                `replace into userMetadata (ncId, phone,profileName,profilePicture, profileThumbnailPicture,allergies,dietaryPreferences,physicalAndMentalSymptoms,temporaryIllnesses) values(?,?,?,?,?,?,?,?,?)`,
                [
                    metadata.ncId,
                    metadata.phone,
                    metadata.profileName,
                    metadata.profilePicture,
                    metadata.profileThumbnailPicture,
                    JSON.stringify(metadata.allergies),
                    JSON.stringify(metadata.dietaryPreferences),
                    JSON.stringify(metadata.physicalAndMentalSymptoms),
                    JSON.stringify(metadata.temporaryIllnesses),
                ]
            );
        });
    }

    private flagMessagesAsTryingToSend(messages: SQLiteMessage[]): Promise<any> {
        return this.dbPromise
            .then(db => {
                if ( !messages || !messages.length ) {
                    return true;
                }
                const paramList = messages.map(() => '?').join(',');
                const paramValues = messages.map(message => message.keyId);
                return db.executeSql(
                    `update messages set tryingToSend = time('now','+30 seconds') where keyId in (${paramList})`,
                    paramValues
                );
            });
    }

    private updateTables(db) {
        return db.executeSql('select version from table_version', [])
            .then(this.resultToSingle)
            .then(versionOBJ => {
                let promise = Promise.resolve(versionOBJ.version);
                // tslint:disable-next-line:triple-equals
                if ( versionOBJ.version == 1 ) {
                    promise = promise
                        .then(() => {
                            return db.executeSql(
                                `create table if not exists meetings(
                                    id,
                                    groupId,
                                    participantsJSON,
                                    description,
                                    date,
                                    place
                                 );`,
                                []
                            );
                        })
                        .then(() => {
                            return db.executeSql(
                                `create table if not exists invitations( id, phone, createdAt, status);`, []
                            );
                        })
                        .then(() => {
                            return db.executeSql(
                                `create table if not exists systemMessage(id, message,createdAt, extras);`, []
                            );
                        })
                        .then(() => {
                            return 2;
                        });
                }

                return promise;
            })
            .then(newVersion => {
                return db.executeSql('update table_version set version = ? ', [newVersion]);
            })
            .then(() => {
                return db;
            })
            .catch(error => {
                console.error(error);
            });
    }

    public addSystemMessage(systemMessage: SystemMessage) {
        return this.dbPromise
            .then(db => {
                return db.executeSql(
                    'insert into systemMessage values (?,?,?,?)',
                    [systemMessage.id, systemMessage.message, systemMessage.createdAt, systemMessage.extras]
                );
            });
    }

    public getSystemMessages(params?: { limit?: number, offset?: number }): Promise<SystemMessage[]> {
        return this.dbPromise
            .then(db => {
                let query = 'select * from systemMessage ';
                const queryParams = [];
                let limit = null;
                let offset = 0;
                if ( params ) {
                    limit = params.limit ? params.limit : null;
                    offset = params.offset ? params.offset : null;
                }
                if ( limit ) {
                    if ( !offset ) {
                        offset = 0;
                    }
                    query += ' limit ? offset ? ';
                    queryParams.push(limit);
                    queryParams.push(offset);
                }
                return db.executeSql(
                    query,
                    queryParams
                );
            })
            .then(this.resultToArray);
    }

    public getContactInvitations(): Promise<Invitation[]> {
        return this.dbPromise
            .then(db => {
                return db.executeSql(
                    'select * from invitations',
                    []
                );
            })
            .then(this.resultToArray);
    }

    public addContactInvitation(
        id,
        phone,
        createdAt,
        status
    ) {
        return this.dbPromise
            .then(db => {
                return db.executeSql(
                    'insert into invitations values (?,?,?,?)',
                    [id, phone, createdAt, status]
                );
            })
            .then(this.resultToArray);
    }

    public updateContactInvitation(id, status) {
        return this.dbPromise
            .then(db => {
                return db.executeSql(
                    'update invitations set status = ? where id = ?',
                    [status, id]
                );
            });
    }

    public deleteContactInvitation(id) {
        return this.dbPromise
            .then(db => {
                return db.executeSql(
                    'delete from invitations where id ?',
                    [id]
                );
            });
    }

    private resultToArray(resultSet) {
        const items = [];
        for ( let i = 0; i < resultSet.rows.length; i++ ) {
            items.push(resultSet.rows.item(i));
        }
        return items;
    }

    private resultToSingle(resultSet) {

        if ( !resultSet.rows.length ) {
            return null;
        }

        return resultSet.rows.item(0);
    }

    private incrementSequence(sequenceName: string) {
        return this.dbPromise.then(
            db => {
                // language=SQLITE-SQL
                return db.executeSql('update db_sequence set nextId = nextId + 1 where name = ?', [sequenceName]);
            }
        );
    }

    private createTables(db) {
        // language=SQLITE-SQL
        return Promise.all(
            [
                db.executeSql('create table if not exists table_version(version)', [])
                    .then(
                        () => {
                            return db.executeSql('insert into table_version(version)  values(?) ', [1]);
                        }),
                db.executeSql(
                    `create table if not exists chat_list(id integer primary key, keyRemoteNcId, messageTableId);`,
                    []
                ),
                db.executeSql(
                    `create table if not exists messages(
                    id integer,
                    keyRemoteNcId,
                    keyFromMe,
                    keyId,
                    status,
                    data,
                    timestamp,
                    mediaURL,
                    controlMessage,
                    mediaName,
                    "date",
                    latitude,
                    longitude,
                    remoteResource,
                    receivedTimestamp,
                    sentTimestamp,
                    receiptServerTimestamp,
                    receiptDeviceTimestamp,
                    imageThumbnail,
                    uploadedMedia default 0,
                    tryingToSend
                    );`,
                    []
                ),
                db.executeSql(
                    'create table if not exists contacts(' +
                    'ncId,' +
                    'phoneId,' +
                    'status,' +
                    'number,' +
                    'displayName,' +
                    'unseenMsgCount' +
                    ');',
                    []
                ),
                db.executeSql(
                    'create table if not exists db_sequence(' +
                    'name,' +
                    'nextId);',
                    []
                ),
                db.executeSql(
                    // tslint:disable-onDismiss-line:max-line-length
                    // tslint:disable-next-line:max-line-length
                    `create table if not exists groupChatMetadata(ncId,locationLatitude,locationLongitude,"date",title,photoURL, photoThumbnail,owner_id,participants, createdAt)`,
                    []
                ).then(success => {
                    console.log(success);
                }, error => {
                    console.log(error);
                }),

                db.executeSql(
                    // tslint:disable-onDismiss-line:max-line-length
                    // tslint:disable-next-line:max-line-length
                    `create table if not exists userMetadata(ncId primary key,allergies,dietaryPreferences,physicalAndMentalSymptoms,temporaryIllnesses,phone,profileName,profilePicture,profileThumbnailPicture)`,
                    []
                ).then(success => {
                    console.log(success);
                }, error => {
                    console.log(error);
                }),
                db.executeSql(
                    'insert into db_sequence(nextId, name) values (0, "message")',
                    []
                ),

            ]
        )
            .then(() => {
                return db;
            });
    }

    createMeeting(meetingId, groupId, participantsJSON, description, date, place) {
        const regex = /\d+-\d+/gm;
        meetingId = regex.exec(meetingId)[0];

        return this.dbPromise.then(db => {
            return db.executeSql(
                `insert into meetings(
                id,
                groupId,
                participantsJSON,
                description,
                date,
                place) values (?,?,?,?,?,?)`,
                [meetingId, groupId, participantsJSON, description, date, place]
            );
        });
    }

    getMeetings(): Promise<Meeting[]> {
        return this.dbPromise.then(db => {
            return db.executeSql('select * from meetings').then(this.resultToArray);
        });
    }
}




