import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { forkJoin, of, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { DataStorageService } from '../data-storage/data-storage.service';

@Injectable({ providedIn: 'root' })
export class InvitesService {
    private invitesChanged$: Subject<any> = new Subject<any>();

    constructor(private dataSvc: DataStorageService, private api: ApiService, private network: Network) {

    }

    sendInvite(phone: any, message: any) {
        return this.api.sendInvite(phone, message)
            .pipe(
                switchMap(({ invite }) => {
                    return fromPromise(this.dataSvc.addContactInvitation(invite.id, invite.phone, invite.createdAt, invite.status)
                        .then(() => this.invitesChanged$.next()));
                }),
                catchError(error => of({
                        isError: true, error
                    })
                )
            );
    }

    getInvites() {
        let observable = fromPromise(this.dataSvc.getContactInvitations());
        if ( this.network.type !== this.network.Connection.NONE ) {
            observable = observable.pipe(
                switchMap((storedInvites) => {
                    return this.api.getInvites().pipe(
                        switchMap(serverInvitations => {

                            let toDelete = [...storedInvites];
                            let toUpdate = [...storedInvites];
                            let toInsert = [...serverInvitations];

                            storedInvites.forEach(storeInvite => {
                                const foundServer = serverInvitations.find(x => x.id === storeInvite.id);
                                if ( !foundServer ) {
                                    toUpdate = toUpdate.filter(x => x.id !== storeInvite.id);
                                    return;
                                }
                                toInsert = toInsert.filter(x => x.id !== storeInvite.id);
                                toDelete = toDelete.filter(x => x.id !== storeInvite.id);
                                if ( storeInvite.status === foundServer.status ) {
                                    toUpdate = toUpdate.filter(x => x.id !== storeInvite.id);
                                }
                            });

                            const observables = [];
                            if ( toDelete.length ) {
                                observables.push(forkJoin(toDelete.map(x => this.dataSvc.deleteContactInvitation(x.id))));
                            }
                            if ( toUpdate.length ) {
                                observables.push(forkJoin(toUpdate.map(x => this.dataSvc.updateContactInvitation(x.id, x.status))));
                            }
                            if ( toInsert.length ) {
                                observables.push(forkJoin(toInsert.map(x => this.dataSvc.addContactInvitation(
                                    x.id,
                                    x.phone,
                                    x.createdAt,
                                    x.status
                                ))));
                            }

                            if ( !observables.length ) {
                                return of(serverInvitations);
                            }

                            return forkJoin(...observables).pipe(
                                map(result => {
                                    console.log('invitations', result);
                                    return serverInvitations;
                                })
                            );
                        })
                    );
                })
            );
        }
        return observable;
    }

    getDBInvites() {
        return this.dataSvc.getContactInvitations();
    }

    fetchInvites() {

    }
}
