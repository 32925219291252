import { DirectoryEntry } from '@ionic-native/file';

declare var webkitRequestFileSystem;
declare global {
    interface Navigator {
        webkitPersistentStorage: {
            requestQuota: (requestedBytes, successCallback, errorCallback) => {}
        };
    }
}

export class FileMock {
    public dataDirectory = 'file:///persistent/';
    private fileSystem: Promise<any>;

    constructor() {
        this.fileSystem = new Promise<any>((resolve, reject) => {
            navigator.webkitPersistentStorage.requestQuota(1024 * 1024 * 1024, (grantedBytes) => {
                webkitRequestFileSystem(1, grantedBytes, fileSystem => {
                    resolve(fileSystem);
                });
            }, function (e) {
                reject(e);
                console.log('Error', e);
            });
        });

    }


    writeFile(path, filename, data: Blob | ArrayBuffer | string) {
        return this.fileSystem
            .then(fs => {
                return new Promise((resolve, reject) => {
                    fs.root.getFile(`${filename}`, { create: true, exclusive: false }, fileEntry => {
                        fileEntry.createWriter((fileWriter) => {
                            fileWriter.onwriteend = (e) => {
                                resolve(fileEntry);
                            };

                            fileWriter.onerror = e => {
                                console.error(e);
                                reject(e);
                            };

                            fileWriter.write(data);
                        });
                    }, e => {
                        console.error(e);
                        reject(e);
                    });
                });
            });
    }

    resolveDirectoryUrl() {
        return this.fileSystem.then(fs => fs.root);
    }


    getFile(directoryEntry: DirectoryEntry, fileName: string, options) {
        return this.fileSystem.then(fs => {
            return new Promise((resolve, reject) => {
                directoryEntry.getFile(fileName, options, fileEntry => {
                    resolve(fileEntry);
                }, error => {
                    reject(error);
                });
            });
        });
    }
}
