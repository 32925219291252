import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { shareReplay, take, tap } from 'rxjs/operators';
import { TokenInterceptor } from '../../interceptors/token.interceptor';
import { Preference } from '../../models/Preference';
import { User } from '../../models/user';
import { Socket } from '../../shared/modules';
import { NCloodUser } from '../contact-manager/contact-manager.service';
import { SQLiteControlMessage, SQLiteMessageStatus, SystemMessage } from '../data-storage/data-storage.service';

export interface ReceiveMessagePayload {
    keyId: string;
    keyRemoteNcId: string;
    data: string;
    status: SQLiteMessageStatus;
    controlMessage: SQLiteControlMessage;
    mediaURL: string;
    imageThumbnail: string;
    locationLatitude: string;
    locationLongitude: string;
    remoteResource: string;
    date: string;
}

export interface SystemMessagePayload {
    data: SystemMessage;
    callback: () => string;
}

export interface ReceiveMessageACKPayload {
    keyId: string;
}

export interface GroupChatImageUploadPayload {
    fileName: string;
    groupChatId: string;
    URL: string;
}

export interface GroupChatImageUploadMessage {
    data: GroupChatImageUploadPayload;
    callback?: (response: any) => string;
}

@Injectable({
    providedIn: 'root'
})
export class CommunicationManagerService {
    public onAuthenticated: Observable<any>;
    public onReceiveMessage: Observable<{ data: ReceiveMessagePayload; callback?: (response: any) => string }>;
    public onUploadGroupImage: Observable<GroupChatImageUploadMessage>;
    public onPing: Observable<{ data: null; callback?: (response: any) => string }>;
    onReceiveMessageACK: Observable<{ data: ReceiveMessageACKPayload; callback?: (response: any) => string }>;
    onSystemMessage: Observable<{ data: SystemMessage; callback?: (response: any) => string }>;

    constructor(private socket: Socket) {
        this.socket.fromEvent('connect').subscribe(() => {
            this.socket.emit('authentication', { token: TokenInterceptor.getToken() });
        });

        this.socket.on('disconnect', () => {
            // console.log('disconnected', reason);
        });
        this.onPing = this.socket.fromEvent<null>('pong').pipe(tap(() => {
            console.log('ping!');
        }));
        this.onAuthenticated = this.socket.fromEvent('authenticated').pipe(
            shareReplay(1),
        );

        // hack to force the authenticated event not to be lost...
        this.onAuthenticated.pipe(take(1)).subscribe();
        this.onReceiveMessage = this.socket.fromEvent<ReceiveMessagePayload>('receiveMessage');
        this.onSystemMessage = this.socket.fromEvent<SystemMessage>('systemMessage');
        this.onReceiveMessageACK = this.socket.fromEvent<ReceiveMessageACKPayload>('receiveMessageACK');
        this.onUploadGroupImage = this.socket.fromEvent<GroupChatImageUploadPayload>('uploadGroupImage');

        TokenInterceptor.decodedToken$.subscribe(() => {
            this.socket.connect();
        });
    }

    public sendEvent(eventName, payload, waitACK = false): Observable<any> {
        if ( !waitACK ) {
            return of(this.socket.emit(eventName, payload));
        }
        return new Observable((observer) => {
            this.socket.emit(eventName, payload, (value) => {
                observer.next(value);
                observer.complete();
            });

            return {
                unsubscribe(): void {
                }
            };
        });
    }

    askForUserMetadata(ncId): Observable<UserMetaData> {
        return this.sendEvent('userMetaData', { ncId }, true);
    }

    askForGroupMetadata(groupNcId) {
        return this.sendEvent(
            'groupMetaData',
            { groupChatId: groupNcId },
            true
        );
    }
}

export interface MetadataError {
    error: true;
}


export interface GroupChatParticipation {
    groupChat_id: string;
    user_id: string;
    administrator: boolean;
    owner: boolean;
    user?: NCloodUser;
}


export interface GroupChatMetaData extends GroupChat {
    participants: GroupChatParticipation[];
}

export interface UserMetaData extends User {
    allergies: Preference[];
    dietaryPreferences: Preference[];
    physicalAndMentalSymptoms: Preference[];
    temporaryIllnesses: Preference[];
}

export interface GroupChat {
    ncId: string;
    locationLatitude: number;
    locationLongitude: number;
    date: string;
    title: string;
    photoURL: string;
    photoThumbnail: string;
    createdAt?: string;
    owner_id: string;
}

export interface ErrorACK {
    error: boolean;
    errorMessage: string;
}

export interface SuccessACK {
    error: boolean;
}

export enum PreferenceType {
    Allergy = 1,
    DietaryPreference = 2,
    PhysicalAndMentalSymptom = 3,
    TemporaryIllness = 4
}

export interface UpdatePreferencesRequest {
    type: PreferenceType;
    data: { id: number }[];
}
