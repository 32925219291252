import { Injectable } from '@angular/core';
import { Contacts } from '@ionic-native/contacts/ngx';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { isArray } from 'util';
import { TokenInterceptor } from '../../interceptors/token.interceptor';
import { CommunicationManagerService, UserMetaData } from '../communication-manager/communication-manager.service';
import { DataStorageService, SQLiteContact } from '../data-storage/data-storage.service';
import { UserService } from '../user/user.service';


@Injectable({
    providedIn: 'root'
})
export class ContactManagerService {

    contactsUpdatedSubject$: Subject<string> = new Subject();

    constructor(
        private dataSvc: DataStorageService,
        private userService: UserService,
        private phoneBookContacts: Contacts,
        private communicationMgr: CommunicationManagerService
    ) {

    }

    getNotNCloodContacts(): Observable<NCloodUser[]> {
        return this.getContacts({ notEnrolled: true });
    }

    fetchContactUpdates(): Observable<boolean> {
        return combineLatest([
                fromPromise(
                    this.phoneBookContacts.find(
                        ['*'],
                        {
                            desiredFields:  [
                                'id',
                                'displayName',
                                'phoneNumbers'
                            ],
                            hasPhoneNumber: true,
                            multiple:       true
                        }
                    )
                ),
                of(TokenInterceptor.getCurrentUser()),
                fromPromise(this.dataSvc.getContacts())
            ]
        ).pipe(
            switchMap(
                ([phoneBookContacts, profile, existingContacts]) => {
                    const parsedNumber = parsePhoneNumberFromString(profile.phone);
                    const countryCallingCode = parsedNumber.countryCallingCode;

                    const updatedAndCreatedObservables = phoneBookContacts.reduce((acc, contact) => {
                        if ( !contact.phoneNumbers || !contact.phoneNumbers.length ) {
                            return acc;
                        }
                        const checkedNumbers = {};

                        contact.phoneNumbers.forEach(phoneNumber => {
                            let currentPhoneNumber = phoneNumber.value.replace(/ /g, '');
                            if ( !currentPhoneNumber.match(/^(00|\+)(\d)+$/) ) {
                                // these are numbers that DO NOT have a country calling code..so we are going to add the user's
                                currentPhoneNumber = `+${countryCallingCode}${currentPhoneNumber}`;
                            }

                            // we try to parse the number and see if it's valid
                            const parsedContactNumber = parsePhoneNumberFromString(currentPhoneNumber);

                            if ( !parsedContactNumber ) {
                                return;
                            }

                            if ( parsedContactNumber.number.toString() === parsedNumber.number.toString() ) {
                                // we don't want to add ourselves in case we save ourselves as contact...
                                return;
                            }

                            if ( checkedNumbers[parsedContactNumber.number.toString()] ) {
                                // we already have this number ..so we don't care anymore
                                return;
                            }

                            checkedNumbers[parsedContactNumber.number.toString()] = true;

                            // we have a valid contact number ..so we should now try to see if we already have this number in the
                            // current contacts array
                            const foundContact = existingContacts.find(
                                existingContact => existingContact.number === parsedContactNumber.number
                            );
                            if ( !foundContact ) {
                                // we couldn't find the guy ..so we have to create it
                                const ncId = `${parsedContactNumber.number.toString()}@nclood.com`;
                                const newContact: SQLiteContact = {
                                    ncId,
                                    phoneId:        contact.id,
                                    number:         parsedContactNumber.number.toString(),
                                    displayName:    contact.displayName,
                                    status:         null,
                                    unseenMsgCount: 0,
                                };

                                acc.toCheckServerPhones.push(newContact);
                                return;
                            }

                            if ( foundContact.displayName !== contact.displayName ) {
                                return acc.updatedAndInsertObservables.push(
                                    fromPromise(
                                        this.dataSvc.updateContact({
                                                ...foundContact,
                                                displayName: contact.displayName,
                                                status:      'nclood'
                                            }
                                        )
                                            .then(() => {
                                                this.contactsUpdatedSubject$.next(foundContact.ncId);
                                            })
                                    )
                                );
                            }
                            return of(false);
                        });
                        return acc;
                    }, {
                        updatedAndInsertObservables: [],
                        toCheckServerPhones:         []
                    });

                    const observables = [];

                    if ( updatedAndCreatedObservables.updatedAndInsertObservables.length ) {
                        observables.push(forkJoin(updatedAndCreatedObservables.updatedAndInsertObservables));
                    }

                    if ( updatedAndCreatedObservables.toCheckServerPhones.length ) {
                        observables.push(forkJoin(updatedAndCreatedObservables.toCheckServerPhones.map(newContact => {
                            return this.communicationMgr.askForUserMetadata(newContact.ncId)
                                .pipe(
                                    switchMap(userMetadata => {
                                        const tasks = [];
                                        if ( userMetadata ) {
                                            newContact.status = 'nclood';
                                            tasks.push(this.dataSvc.updateUserMetadata(userMetadata));
                                        }
                                        tasks.push(this.dataSvc.createContact(newContact));
                                        return fromPromise(Promise.all(tasks))
                                            .pipe(
                                                map(() => {
                                                    this.contactsUpdatedSubject$.next(newContact.ncId);
                                                    return true;
                                                })
                                            );
                                    })
                                );
                        })));
                    }
                    if ( !observables.length ) {
                        return of(false);
                    }

                    return combineLatest(observables)
                        .pipe(
                            map((forkJoinResult: any[]) => {
                                if ( !forkJoinResult.length ) {
                                    return false;
                                }

                                return forkJoinResult.reduce((acc, array) => {
                                    if ( !isArray(array) ) {
                                        acc = acc || array;
                                        return acc;
                                    }
                                    acc = acc || (<[]>array).some(x => !!x);
                                    return acc;
                                }, false);
                            })
                        );
                }
            )
        );
    }

    /**
     * Returns all the contacts with their metadata as an NCloodUser
     */
    getContacts(params: { searchTerm?: string, limit?: number, offset?: number, notEnrolled?: boolean } = null): Observable<NCloodUser[]> {
        return this.contactsUpdatedSubject$.pipe(
            startWith(<string>null),
            switchMap(() => {
                return fromPromise(this.dataSvc.getContacts(params));
            }),
            switchMap(contacts => {
                if ( !contacts.length ) {
                    return of([]);
                }
                return forkJoin(
                    contacts.map(
                        (contact) => fromPromise(this.dataSvc.getUserMetadata(contact.ncId)).pipe(map(metadata => {
                            return { ncId: contact.ncId, contact: contact, metadata: metadata };
                        }))
                    )
                );
            })
        );
    }

    getUser(ncId: string): Observable<NCloodUser> {
        return this.contactsUpdatedSubject$.pipe(
            startWith(ncId),
            filter(updatedContactId => updatedContactId === ncId),
            switchMap(() => {
                return fromPromise(Promise.all([this.dataSvc.getContact(ncId), this.dataSvc.getUserMetadata(ncId)]));
            }),
            map(([contact, metadata]) => {
                return { ncId, contact, metadata };
            })
        );
    }

    fetchSingleContactUpdate(ncId: string | any) {
        return this.communicationMgr.askForUserMetadata(ncId)
            .pipe(
                switchMap(userMetadata => {
                    if ( !userMetadata ) {
                        return of(null);
                    }
                    return this.dataSvc.updateUserMetadata(userMetadata).then(() => {
                        this.contactsUpdatedSubject$.next(ncId);
                    });
                })
            );
    }
}

export interface NCloodUser {
    ncId: string;
    contact: SQLiteContact;
    metadata: UserMetaData;
}
