import { ModuleWithProviders, NgModule } from '@angular/core';
import { SocketIoConfig } from './interfaces/socket-io.config';
import { SOCKET_CONFIG_TOKEN, SocketFactory, WrappedSocket } from './socket-io.service';

@NgModule({})
export class SocketIoModule {
    public static forRoot(config: SocketIoConfig): ModuleWithProviders {
        return {
            ngModule:  SocketIoModule,
            providers: [
                { provide: SOCKET_CONFIG_TOKEN, useValue: config },
                {
                    provide:    WrappedSocket,
                    useFactory: SocketFactory,
                    deps:       [SOCKET_CONFIG_TOKEN]
                }
            ]
        };
    }
}
