import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { combineLatest, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, concatMap, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Error } from 'tslint/lib/error';
import { TokenInterceptor } from '../../interceptors/token.interceptor';
import { Preference } from '../../models/Preference';
import { DisplayGroup, DisplayParticipant } from '../../pages/chat/group-detail/group-detail.page';
import { generateFromImage } from '../../shared/utility/thumbnailGenerator';
import {
    CommunicationManagerService,
    GroupChatMetaData,
    PreferenceType,
    ReceiveMessagePayload
} from '../communication-manager/communication-manager.service';
import { ContactManagerService } from '../contact-manager/contact-manager.service';
import {
    DataStorageService,
    SQLiteChat,
    SQLiteControlMessage,
    SQLiteMessage,
    SQLiteMessageKeyFromMe,
    SQLiteMessageStatus,
    SystemMessage
} from '../data-storage/data-storage.service';
import { MeetingService } from '../meeting/meeting.service';
import { UserService } from '../user/user.service';

export interface ImageUploadResponse {
    error: boolean;
    errorMessage?: string;
}

@Injectable({ providedIn: 'root' })
export class ChatManagerService implements OnDestroy {
    private onDestroy$ = new Subject();
    messageChangedSubject: Subject<string> = new Subject();
    groupMetadataChangedSubject: Subject<string> = new Subject();
    private readonly startTimestamp;
    private messagesCount = 0;
    private onPendingToSendMessageAddedSubject$: Subject<any> = new Subject<any>();

    constructor(
        private dataStorage: DataStorageService,
        private communicationManager: CommunicationManagerService,
        private contactManager: ContactManagerService,
        private userService: UserService,
        private file: File,
        private http: HttpClient,
        private meetingSvc: MeetingService
    ) {
        console.log('chat-manager service created!');
        this.startTimestamp = Date.now();
        // when we have a pong it means we are connected to the server ..so we should definitely try to send things that are pending to be
        // sent

        this.communicationManager.onUploadGroupImage.pipe(
            switchMap(message => {
                // server is telling us to upload the file
                const promise = this.file.resolveDirectoryUrl(this.file.dataDirectory)
                    .then(dataDirectory => {
                        return this.file.getFile(dataDirectory, message.data.fileName, null);
                    });
                return fromPromise(promise)
                    .pipe(
                        switchMap(fileEntry => {
                            // we have the file, we are going to upload it
                            return this.http.post <ImageUploadResponse>(message.data.URL, { file: fileEntry });
                        }),
                        map(response => {
                            if ( response.error ) {
                                return;
                            }
                            message.callback({ success: true });
                        })
                    );
            })
        );
        combineLatest([
            combineLatest([
                this.communicationManager.onAuthenticated,
                this.onPendingToSendMessageAddedSubject$.pipe(startWith(<string>null))]
            ).pipe(
                switchMap(() => this.getMessagesPendingToSend()),
                tap((messagesToBeSent) => {
                    console.log('messages to be sent', messagesToBeSent);
                }),
                switchMap(messagesPending => {
                    return from(messagesPending);
                }),
                concatMap(messageToBeSent => {
                    console.log('sending message ', messageToBeSent);
                    return this.handleMessageSend(messageToBeSent)
                        .pipe(
                            tap(() => {
                                this.messageChangedSubject.next(messageToBeSent.keyRemoteNcId);
                            }),
                            catchError(error => {
                                console.error(error);
                                return of(null);
                            })
                        );
                })
            ),
            this.communicationManager.onReceiveMessage.pipe(
                tap(
                    ({ data, callback }) => {
                        console.log('we have received a new message!', data);
                    }
                ),
                switchMap(payload => {
                    return this.handleMessageReceive(payload.data)
                        .pipe(
                            tap((message) => {
                                payload.callback({ success: true });
                                if ( message ) {
                                    this.messageChangedSubject.next(message.keyRemoteNcId);
                                }
                            }),
                            catchError(error => {
                                console.error(error);
                                payload.callback({ error: true, message: JSON.stringify(error), });
                                return of(null);
                            })
                        );
                }),
                startWith(<string>null)
            ),
            this.communicationManager.onReceiveMessageACK.pipe(
                switchMap(message => {
                    return this.handleMessageReceiveACK(message.data.keyId).pipe(
                        tap((ackMessage) => {
                            this.messageChangedSubject.next(ackMessage.keyRemoteNcId);
                            message.callback({ success: true });
                        }),
                        catchError(error => {
                            message.callback({ error: true, message: error });
                            return of(null);
                        })
                    );
                }),
                startWith(<string>null)
            ),
            this.communicationManager.onSystemMessage.pipe(
                switchMap(systemMessagePayload => {
                    return this.handleSystemMessage(systemMessagePayload.data).pipe(
                        tap(() => {
                            systemMessagePayload.callback({ success: true });
                        })
                    );
                })
            )
        ])
            .pipe(
                catchError((error: Error) => {
                    console.error(error);
                    return of(null);
                }),
                takeUntil(this.onDestroy$)
            )
            .subscribe((result) => {
                console.log(result);
            });
    }

    getChat(ncId): Observable<SQLiteChat> {
        return fromPromise(this.dataStorage.getChat(ncId));
    }

    handleSystemMessage(systemMessage: SystemMessage) {
        return fromPromise(this.dataStorage.addSystemMessage(systemMessage));
    }

    getMessagesForChat(chatId: string, itemsPerPage = 10, page = 1) {
        return this.messageChangedSubject.pipe(
            startWith(chatId),
            filter(givenChatId => chatId === givenChatId),
            switchMap(() => {
                return fromPromise(this.dataStorage.getMessagesForChat(chatId, itemsPerPage, page));
            })
        );
    }

    public sendMessage(
        chatId: any,
        messageInput: string,
        keyId: string = null
    ) {
        return this.addPendingToSendMessage(
            {
                keyRemoteNcId: chatId,
                data:          messageInput,
                keyId,
                keyFromMe:     SQLiteMessageKeyFromMe.Outgoing,
                status:        SQLiteMessageStatus.Received,
            }
        );
    }

    getChats() {
        return this.messageChangedSubject.pipe(
            startWith(<string>null),
            switchMap(() => {
                return combineLatest(
                    [
                        fromPromise(this.dataStorage.getChats()),
                        fromPromise(this.dataStorage.getSystemMessages({ limit: 1, offset: 0 }))
                    ]);
            }),
            map(([chats, systemMessages]) => {
                if ( systemMessages.length ) {
                    chats.push(
                        {
                            keyRemoteNcId:  'system',
                            messageTableId: 'system',
                            lastMessage:    {
                                data:          systemMessages[0].message,
                                keyRemoteNcId: 'system',
                                keyFromMe:     SQLiteMessageKeyFromMe.Incoming,
                                status:        SQLiteMessageStatus.Received,
                            }
                        }
                    );
                }
                return chats;
            })
        );
    }

    createGroupChat(ncIds: string[], name = null, date = null, latitude = null, longitude = null, photo: string = null) {
        // if we are provided an image, we are going to generate a signature from it, and we are going to use the signature and the name
        // to store the image in our file path, we are going to assume the photo field is going to be a base64 encoded image
        return this.userService.getProfile().pipe(
            switchMap(profile => {
                const parsedNumber = parsePhoneNumberFromString(profile.phone);
                const idOnly = `${parsedNumber.number}-${Date.now()}`;
                const chatGroupId = `${idOnly}@nclood.com`;
                const remoteResource = `${parsedNumber.number}@nclood.com`;
                const createGroupMessage: SQLiteMessage = {
                    keyRemoteNcId:  chatGroupId,
                    data:           name,
                    keyId:          null,
                    keyFromMe:      SQLiteMessageKeyFromMe.Outgoing,
                    status:         SQLiteMessageStatus.ControlMessage,
                    controlMessage: SQLiteControlMessage.GroupCreation,
                    latitude:       latitude,
                    longitude:      longitude,
                    date:           date,
                    remoteResource
                };

                let obs = of(createGroupMessage);
                if ( photo ) {
                    // we have a photo, so we need to store it in a file, generate a name from it
                    obs = fromPromise(
                        this.storeBase64ToFile(photo)
                            .then((fileEntry) => {
                                createGroupMessage.mediaName = fileEntry.name;
                                createGroupMessage.mediaURL = fileEntry.toURL();
                                return generateFromImage(photo).then(thumbnail => {
                                    createGroupMessage.imageThumbnail = thumbnail;
                                    return createGroupMessage;
                                });
                            })
                    );
                }
                return obs
                    .pipe(
                        switchMap((processedMessage) => this.addPendingToSendMessage(processedMessage)),
                        switchMap(addedMessage => {
                            const participants = [
                                { groupChat_id: createGroupMessage.keyRemoteNcId, user_id: profile.ncId, administrator: true, owner: true },
                                ...ncIds.map(ncId => {
                                    return {
                                        groupChat_id:  createGroupMessage.keyRemoteNcId,
                                        user_id:       ncId,
                                        administrator: false,
                                        owner:         false
                                    };
                                })
                            ];

                            return fromPromise(this.dataStorage.createGroupChatMetadata({
                                ncId:              createGroupMessage.keyRemoteNcId,
                                locationLatitude:  createGroupMessage.latitude,
                                locationLongitude: createGroupMessage.longitude,
                                date:              createGroupMessage.date,
                                title:             createGroupMessage.data,
                                photoURL:          createGroupMessage.mediaURL,
                                photoThumbnail:    createGroupMessage.imageThumbnail,
                                owner_id:          createGroupMessage.remoteResource,
                                participants
                            })).pipe(map(() => addedMessage));
                        }),
                        switchMap(addedMessage => {
                            return forkJoin(
                                [
                                    of(addedMessage),
                                    ...this.addUsersToGroup(ncIds.filter(ncId => ncId !== profile.ncId), createGroupMessage.keyRemoteNcId)
                                ]
                            );
                        })
                    );
            }),

            switchMap((allMessages) => {

                return of(null);
            })
        );
    }

    addUserToGroup(ncId, groupNcId): Observable<any> {
        const message: SQLiteMessage = {
            keyRemoteNcId:  groupNcId,
            data:           ncId,
            keyId:          null,
            keyFromMe:      SQLiteMessageKeyFromMe.Outgoing,
            status:         SQLiteMessageStatus.ControlMessage,
            controlMessage: SQLiteControlMessage.GroupJoin,
            remoteResource: TokenInterceptor.getCurrentUser().ncId
        };

        return this.addPendingToSendMessage(message);
    }

    addUsersToGroup(ncIds, groupNcId, changeMetadata = false): Observable<any>[] {
        return ncIds.map(ncId => {
            return this.addUserToGroup(ncId, groupNcId)
                .pipe(switchMap(() => {
                    if ( !changeMetadata ) {
                        return of(null);
                    }

                    return fromPromise(this.dataStorage.getGroupChatMetadata(groupNcId))
                        .pipe(switchMap(groupChatMetadata => {
                            const foundParticipant = groupChatMetadata.participants.find(x => x.user_id === ncId);
                            if ( foundParticipant ) {
                                return of(null);
                            }

                            groupChatMetadata.participants.push({
                                groupChat_id:  groupNcId,
                                user_id:       ncId,
                                administrator: false,
                                owner:         false
                            });
                            return this.dataStorage.updateGroupChatMetadata(groupChatMetadata).then(() => {
                                this.groupMetadataChangedSubject.next(groupNcId);
                            });
                        }));
                }));
        });
    }

    updateGroupChatPhoto(chatGroupId, photoBase64) {
        return this.userService.getProfile()
            .pipe(
                switchMap(profile => {
                    const parsedNumber = parsePhoneNumberFromString(profile.phone);
                    const remoteResource = `${parsedNumber.number}@nclood.com`;
                    const updateGroupImageMessage: SQLiteMessage = {
                        keyRemoteNcId:  chatGroupId,
                        data:           name,
                        keyId:          null,
                        keyFromMe:      SQLiteMessageKeyFromMe.Outgoing,
                        status:         SQLiteMessageStatus.ControlMessage,
                        controlMessage: SQLiteControlMessage.GroupPictureUpdate,
                        remoteResource
                    };
                    return fromPromise(this.storeBase64ToFile(photoBase64)
                        .then(fileEntry => {
                            updateGroupImageMessage.mediaName = fileEntry.name;
                            updateGroupImageMessage.mediaURL = fileEntry.toURL();
                            return generateFromImage(photoBase64)
                                .then(thumbnail => {
                                    updateGroupImageMessage.imageThumbnail = thumbnail;
                                    return updateGroupImageMessage;
                                });
                        }));
                }),
                switchMap(message => {
                    return this.addPendingToSendMessage(message);
                })
            );
    }

    public getGroupChatMetaData(groupChatId): Observable<GroupChatMetaData> {
        return this.groupMetadataChangedSubject.pipe(
            startWith(groupChatId as string),
            filter(x => x === groupChatId),
            switchMap(() => {
                return fromPromise(this.dataStorage.getGroupChatMetadata(groupChatId));
            }),
            switchMap(groupChatMetadata => {

                if ( !groupChatMetadata.participants.length ) {
                    return of([])
                        .pipe(
                            map(array => {
                                groupChatMetadata.participants = array;
                                return groupChatMetadata;
                            })
                        );
                }

                return forkJoin(groupChatMetadata.participants.map(participant => {
                        return this.contactManager.getUser(participant.user_id)
                            .pipe(
                                take(1),
                                map(nCloodUser => {
                                    return { ...participant, user: nCloodUser };
                                })
                            );
                    })
                ).pipe(
                    map(array => {
                        groupChatMetadata.participants = array;
                        return groupChatMetadata;
                    }));

            }),
        );

    }

    sendProfilePictureUpdateMessage(thumbnail, picture) {
        const currentUser = TokenInterceptor.getCurrentUser();
        return fromPromise(this.dataStorage.getUserMetadata(currentUser.ncId)).pipe(
            switchMap(userMetadata => {
                const message: SQLiteMessage = {
                    keyRemoteNcId:  currentUser.ncId,
                    status:         SQLiteMessageStatus.ControlMessage,
                    controlMessage: SQLiteControlMessage.ProfilePictureUpdate,
                    keyFromMe:      SQLiteMessageKeyFromMe.Outgoing,
                    data:           null
                };


                // we have a photo, so we need to store it in a file, generate a name from it
                return fromPromise(this.storeBase64ToFile(picture)
                    .then((fileEntry) => {
                        message.mediaName = fileEntry.name;
                        message.mediaURL = fileEntry.toURL();
                        message.imageThumbnail = thumbnail;
                        return message;
                    })
                    .then(() => {
                        userMetadata.profileThumbnailPicture = thumbnail;
                        userMetadata.profilePicture = message.mediaURL;
                        return this.dataStorage.updateUserMetadata(userMetadata);
                    }))
                    .pipe(
                        switchMap(() => {
                            return this.addPendingToSendMessage(message);
                        })
                    );
            }),
            map(x => !!x)
        );
    }

    sendPreferencesUpdateMessage(preferenceType: PreferenceType, preferences: Preference[]) {
        const currentUser = TokenInterceptor.getCurrentUser();
        return fromPromise(this.dataStorage.getUserMetadata(currentUser.ncId)).pipe(
            switchMap(userMetadata => {

                let controlMessage = null;
                switch (preferenceType) {
                    case PreferenceType.Allergy:
                        controlMessage = SQLiteControlMessage.AllergyUpdate;
                        userMetadata.allergies = preferences;
                        break;
                    case PreferenceType.DietaryPreference:
                        controlMessage = SQLiteControlMessage.DietaryUpdate;
                        userMetadata.dietaryPreferences = preferences;
                        break;
                    case PreferenceType.PhysicalAndMentalSymptom:
                        controlMessage = SQLiteControlMessage.PhysicalAndMentalUpdate;
                        userMetadata.physicalAndMentalSymptoms = preferences;
                        break;
                    case PreferenceType.TemporaryIllness:
                        controlMessage = SQLiteControlMessage.TemporaryIllnessUpdate;
                        userMetadata.temporaryIllnesses = preferences;
                        break;
                }

                return fromPromise(this.dataStorage.updateUserMetadata(userMetadata)).pipe(
                    switchMap(() => {
                        const message: SQLiteMessage = {
                            keyRemoteNcId: currentUser.ncId,
                            status:        SQLiteMessageStatus.ControlMessage,
                            controlMessage,
                            data:          JSON.stringify(preferences.map(x => x.id)),
                            keyFromMe:     SQLiteMessageKeyFromMe.Outgoing,
                        };
                        return this.addPendingToSendMessage(message);
                    })
                );
            }),
            map(x => !!x)
        );
    }

    removeParticipantFromGroup(group: DisplayGroup, participant: DisplayParticipant, isCurrentUserGroupAdmin) {
        if ( !(isCurrentUserGroupAdmin || participant.isCurrentUser) ) {
            // you can't remove the guy ...sorry
            throw new Error('You cannot remove this participant');
        }

        const message: SQLiteMessage = {
            keyRemoteNcId:  group.ncId,
            controlMessage: SQLiteControlMessage.GroupLeave,
            keyFromMe:      SQLiteMessageKeyFromMe.Outgoing,
            remoteResource: TokenInterceptor.getCurrentUser().ncId,
            status:         SQLiteMessageStatus.ControlMessage,
            data:           participant.user_id
        };
        return this.addPendingToSendMessage(message).pipe(switchMap(() => {
            // we have to update the group metadata to remove people for the chat...
            return this.dataStorage.getGroupChatMetadata(group.ncId)
                .then(groupChatMetadata => {
                    groupChatMetadata.participants = groupChatMetadata.participants.filter(x => x.user_id !== participant.user_id);
                    this.dataStorage.updateGroupChatMetadata(groupChatMetadata);
                })
                .then(() => {
                    this.groupMetadataChangedSubject.next(group.ncId);
                });
        }));
    }

    private readFileAsDataURL(fileEntry) {
        return new Promise((resolve, reject) => {
            fileEntry.file(file => {
                const reader = new FileReader();
                reader.onloadend = e => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(file);
            }, err => reject(err));
        });
    }

    private handleGroupChatUpload(messageToBeSent) {
        return fromPromise(this.file.resolveDirectoryUrl(this.file.dataDirectory))
            .pipe(
                switchMap(directoryEntry => {
                    return fromPromise(this.file.getFile(directoryEntry, messageToBeSent.mediaName, {}));
                }),
                switchMap(fileEntry => fromPromise(this.readFileAsDataURL(fileEntry))),
                switchMap(dataURLFileContent => {
                    return this.http.post<{ error?: boolean, errorDescription?: string, success?: true, imageURL: string }>(
                        'http://68.183.38.142/imageUpload',
                        { file: dataURLFileContent }
                    );
                }),
                switchMap(response => {
                    if ( response.error ) {
                        throw new Error(response.errorDescription);
                    }
                    const updatedMessage = { ...messageToBeSent, mediaURL: response.imageURL, uploadedMedia: true };
                    // we should update the metadata as it should hold the information about the group chat
                    return fromPromise(this.dataStorage.updateMessage(updatedMessage)
                        .then(() => {
                            return updatedMessage;
                        })
                    );
                }),
                switchMap((updatedMessage) => {
                    return fromPromise(this.dataStorage.getGroupChatMetadata(updatedMessage.keyRemoteNcId)).pipe(
                        switchMap(groupChatMetadata => {
                            groupChatMetadata.photoThumbnail = updatedMessage.imageThumbnail;
                            groupChatMetadata.photoURL = updatedMessage.mediaURL;
                            return fromPromise(this.dataStorage.updateGroupChatMetadata(groupChatMetadata)
                                .then(() => {
                                    return updatedMessage;
                                })
                            );
                        }),
                        tap(() => {
                            this.groupMetadataChangedSubject.next(updatedMessage.keyRemoteNcId);
                        })
                    );
                })
            );
    }

    // TODO: we should try to flag the messages we are trying to send to make sure they don't get sent again
    private handleMessageSend(messageToBeSent: SQLiteMessage) {
        let observable = of(messageToBeSent);
        if ( !messageToBeSent.uploadedMedia && messageToBeSent.status === SQLiteMessageStatus.ControlMessage &&
            (messageToBeSent.controlMessage === SQLiteControlMessage.GroupCreation ||
                messageToBeSent.controlMessage === SQLiteControlMessage.GroupPictureUpdate
            ) &&
            messageToBeSent.mediaName ) {

            // this is a group create message with a photo, so we have to upload the photo to the server
            observable = this.handleGroupChatUpload(messageToBeSent);
        }

        if ( !messageToBeSent.uploadedMedia && messageToBeSent.status === SQLiteMessageStatus.ControlMessage &&
            (messageToBeSent.controlMessage === SQLiteControlMessage.ProfilePictureUpdate) ) {
            observable = this.handleProfilePictureUpload(messageToBeSent);
        }

        return observable
            .pipe(
                switchMap((message) => {
                    return this.communicationManager.sendEvent('sendMessage', message, true)
                        .pipe(
                            tap((ack) => {
                                console.log(ack);
                            }),
                            switchMap(ack => {
                                if ( ack.error ) {
                                    return of(null);
                                }

                                // the server has confirmed the reception of the message
                                message.receiptServerTimestamp = Date.now();
                                if ( message.status !== SQLiteMessageStatus.ControlMessage ) {
                                    message.status = SQLiteMessageStatus.WaitingServer;
                                }

                                return fromPromise(this.dataStorage.updateMessage(message))
                                    .pipe(
                                        map(() => {
                                            return message;
                                        })
                                    );
                            }),
                            catchError(error => {
                                console.error(error);
                                return of(null);
                            }),
                            tap(() => {
                                this.dataStorage.stopTryingToSendMessage(message);
                            })
                        );
                })
            );
    }

    public sendCreateMeetingMessage(groupId, participants, description, date, place) {
        const message: SQLiteMessage = {
            keyRemoteNcId:  groupId,
            data:           JSON.stringify(participants),
            keyFromMe:      SQLiteMessageKeyFromMe.Outgoing,
            status:         SQLiteMessageStatus.ControlMessage,
            controlMessage: SQLiteControlMessage.MeetingCreated,
            date,
            mediaURL:       description,
            imageThumbnail: place,
        };
        return this.addPendingToSendMessage(message).pipe(
            take(1),
            switchMap((modifiedMessage) => {
                return this.dataStorage.createMeeting(
                    modifiedMessage.keyId,
                    groupId,
                    JSON.stringify(participants),
                    description,
                    date,
                    place
                );
            })
        );
    }

    private addPendingToSendMessage(message: SQLiteMessage) {
        return this.addMessage(message).pipe(
            tap(() => {
                this.onPendingToSendMessageAddedSubject$.next();
            })
        );
    }

    private addMessage(message: SQLiteMessage): Observable<SQLiteMessage> {
        if ( !message.keyId ) {
            // this is a message we are sending, so we have to generate a key_id for it
            message.keyId = `${this.startTimestamp}-${this.messagesCount++}`;
        }
        return fromPromise(this.dataStorage.getChat(message.keyRemoteNcId))
            .pipe(
                switchMap(existingChat => {
                    return fromPromise(
                        this.dataStorage.getMessage(message.keyId)
                            .then(storedMessage => {
                                let nextPromise = Promise.resolve(storedMessage);
                                if ( !storedMessage ) {
                                    // we only create messages if we don't have them already based on the id...
                                    nextPromise = this.dataStorage.createMessage(message);
                                }
                                return nextPromise;
                            })
                    )
                        .pipe(
                            switchMap(createdMessage => {
                                if ( createdMessage.keyRemoteNcId === TokenInterceptor.getCurrentUser().ncId ) {
                                    return of(createdMessage);
                                }

                                if ( !existingChat ) {
                                    return fromPromise(
                                        this.dataStorage.createChat(
                                            createdMessage.keyRemoteNcId,
                                            createdMessage.keyId
                                        )
                                    ).pipe(map(() => {
                                        return createdMessage;
                                    }));
                                }
                                return fromPromise(
                                    this.dataStorage.updateChat(
                                        createdMessage.keyRemoteNcId,
                                        createdMessage.keyId
                                    )
                                ).pipe(map(() => {
                                    return createdMessage;
                                }));

                            }),
                            tap(() => {
                                // we can notify about the message being sent as soon as we store it in the DB
                                this.messageChangedSubject.next(message.keyRemoteNcId);
                            }),
                        );
                })
            );
    }

    private handleMessageReceiveACK(keyId: any) {
        // we have to update the message and change it's status to show that the other party has received and confirmed it
        return fromPromise(this.dataStorage.getMessage(keyId))
            .pipe(
                switchMap((message) => {
                    message.status = SQLiteMessageStatus.DestinationReceived;
                    message.receiptDeviceTimestamp = Date.now();
                    return fromPromise(this.dataStorage.updateMessage(message))
                        .pipe(
                            map(() => {
                                return message;
                            })
                        );
                })
            );
    }

    private generateUUID() {
        const array = new Uint32Array(8);
        window.crypto.getRandomValues(array);
        let str = '';
        for ( let i = 0; i < array.length; i++ ) {
            str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4);
        }
        return str;
    }

    private storeBase64ToFile(photoBase64): Promise<FileEntry> {
        const MIMEType = base64MimeType(photoBase64);
        if ( !ImageTypes[MIMEType] ) {
            throw new Error('MIME Type not valid!!');
        }
        const fileName = `${this.generateUUID()}.${ImageTypes[MIMEType]}`;
        return this.file.writeFile(this.file.dataDirectory, fileName, dataURIToBlob(photoBase64));
    }

    demoteParticipantFromGroup(group: DisplayGroup, participant: DisplayParticipant, isCurrentUserGroupAdmin: boolean) {
        if ( !(isCurrentUserGroupAdmin) ) {
            // you can't remove the guy ...sorry
            throw new Error('You cannot promote this participant');
        }

        const message: SQLiteMessage = {
            keyRemoteNcId:  group.ncId,
            controlMessage: SQLiteControlMessage.GroupDemoteUser,
            keyFromMe:      SQLiteMessageKeyFromMe.Outgoing,
            remoteResource: TokenInterceptor.getCurrentUser().ncId,
            status:         SQLiteMessageStatus.ControlMessage,
            data:           participant.user_id
        };
        return this.addPendingToSendMessage(message).pipe(switchMap(() => {
            // we have to update the group metadata to promote people for the chat...
            return this.dataStorage.getGroupChatMetadata(group.ncId)
                .then(groupChatMetadata => {
                    const foundParticipant = groupChatMetadata.participants.find(x => x.user_id === participant.user_id);
                    foundParticipant.administrator = false;
                    this.dataStorage.updateGroupChatMetadata(groupChatMetadata);
                })
                .then(() => {
                    this.groupMetadataChangedSubject.next(group.ncId);
                });
        }));
    }

    private getMessagesPendingToSend(): Promise<SQLiteMessage[]> {
        return this.dataStorage.getMessagesPendingToSend();
    }

    promoteParticipantFromGroup(group: DisplayGroup, participant: DisplayParticipant, isCurrentUserGroupAdmin: boolean) {
        if ( !(isCurrentUserGroupAdmin || participant.isCurrentUser) ) {
            // you can't remove the guy ...sorry
            throw new Error('You cannot promote this participant');
        }

        const message: SQLiteMessage = {
            keyRemoteNcId:  group.ncId,
            controlMessage: SQLiteControlMessage.GroupPromoteUser,
            keyFromMe:      SQLiteMessageKeyFromMe.Outgoing,
            remoteResource: TokenInterceptor.getCurrentUser().ncId,
            status:         SQLiteMessageStatus.ControlMessage,
            data:           participant.user_id
        };
        return this.addPendingToSendMessage(message).pipe(switchMap(() => {
            // we have to update the group metadata to promote people for the chat...
            return this.dataStorage.getGroupChatMetadata(group.ncId)
                .then(groupChatMetadata => {
                    const foundParticipant = groupChatMetadata.participants.find(x => x.user_id === participant.user_id);
                    foundParticipant.administrator = true;
                    this.dataStorage.updateGroupChatMetadata(groupChatMetadata);
                })
                .then(() => {
                    this.groupMetadataChangedSubject.next(group.ncId);
                });
        }));
    }

    private handleMessageReceive(messageToReceive: ReceiveMessagePayload): Observable<SQLiteMessage> {
        // we have received a message
        return of(messageToReceive).pipe(
            map(x => {
                return { ...x, keyFromMe: SQLiteMessageKeyFromMe.Incoming };
            }),
            switchMap((message) => {
                if ( message.keyRemoteNcId.indexOf('-') !== -1 ) {
                    // this is a group message!!
                    return fromPromise(this.dataStorage.getGroupChatMetadata(message.keyRemoteNcId))
                        .pipe(
                            switchMap(chat => {
                                if ( !chat ) {
                                    // the chat doesn't exist!!! ..it's new
                                    console.log('trying to pull group metadata...');
                                    return this.communicationManager.askForGroupMetadata(message.keyRemoteNcId)
                                        .pipe(
                                            switchMap(metadata => {
                                                    return fromPromise(this.dataStorage.createGroupChatMetadata(metadata))
                                                        .pipe(
                                                            switchMap(() => {
                                                                    return forkJoin(metadata.participants.map(participant => {
                                                                        // tslint:disable-next-line:max-line-length
                                                                        return this.contactManager.fetchSingleContactUpdate(participant.ncId);
                                                                    }));
                                                                }
                                                            )
                                                        );
                                                }
                                            ),
                                            map(() => {
                                                this.groupMetadataChangedSubject.next(message.keyRemoteNcId);
                                                return message;
                                            })
                                        );
                                }
                                let next = Promise.resolve(message);
                                if ( message.status === SQLiteMessageStatus.ControlMessage ) {
                                    switch (message.controlMessage) {
                                        case SQLiteControlMessage.GroupDateUpdate:
                                            next = this.dataStorage.getGroupChatMetadata(message.keyRemoteNcId)
                                                .then((metadata) => {
                                                    metadata.date = message.data;
                                                    return this.dataStorage.updateGroupChatMetadata(metadata);
                                                });
                                            break;
                                        case SQLiteControlMessage.GroupJoin:
                                            next = this.dataStorage.getGroupChatMetadata(message.keyRemoteNcId)
                                                .then((metadata) => {

                                                    if ( metadata.participants.findIndex(x => x.user_id === message.data) > -1 ) {
                                                        return;
                                                    }

                                                    metadata.participants.push({
                                                        groupChat_id:  message.keyRemoteNcId,
                                                        user_id:       message.data,
                                                        administrator: false,
                                                        owner:         false
                                                    });
                                                    // we might not have user metadata
                                                    return this.contactManager.getUser(message.data).pipe(
                                                        take(1),
                                                        switchMap(user => {
                                                            if ( user.metadata ) {
                                                                return of(null);
                                                            }
                                                            return this.communicationManager.askForUserMetadata(message.data)
                                                                .pipe(
                                                                    switchMap(userMetadata => {
                                                                        return this.dataStorage.updateUserMetadata(userMetadata);
                                                                    })
                                                                );
                                                        }),
                                                        switchMap(() => {
                                                            return this.dataStorage.updateGroupChatMetadata(metadata);
                                                        })
                                                    ).toPromise();
                                                });
                                            break;
                                        case SQLiteControlMessage.GroupPictureUpdate:
                                            next = this.dataStorage.getGroupChatMetadata(message.keyRemoteNcId)
                                                .then((metadata) => {
                                                    metadata.photoThumbnail = message.imageThumbnail;
                                                    metadata.photoURL = message.mediaURL;
                                                    return this.dataStorage.updateGroupChatMetadata(metadata);
                                                });

                                            break;
                                        case SQLiteControlMessage.GroupTitleUpdate:
                                            next = this.dataStorage.getGroupChatMetadata(message.keyRemoteNcId)
                                                .then((metadata) => {
                                                    metadata.title = message.data;
                                                    return this.dataStorage.updateGroupChatMetadata(metadata);
                                                });
                                            break;
                                        case SQLiteControlMessage.GroupLeave:
                                            next = this.dataStorage.getGroupChatMetadata(message.keyRemoteNcId)
                                                .then((metadata) => {
                                                    // tslint:disable-next-line:max-line-length
                                                    metadata.participants = metadata.participants
                                                        .filter(x => x.user_id !== message.data);
                                                    return this.dataStorage.updateGroupChatMetadata(metadata);
                                                });
                                            break;
                                        case SQLiteControlMessage.GroupPromoteUser:
                                            next = this.dataStorage.getGroupChatMetadata(message.keyRemoteNcId)
                                                .then((metadata) => {
                                                    // tslint:disable-next-line:max-line-length
                                                    metadata.participants = metadata.participants
                                                        .map(x => {
                                                            return {
                                                                ...x,
                                                                administrator: x.user_id === message.data ? true : x.administrator
                                                            };
                                                        });
                                                    return this.dataStorage.updateGroupChatMetadata(metadata);
                                                });
                                            break;
                                        case SQLiteControlMessage.MeetingCreated:
                                            next = this.dataStorage.createMeeting(
                                                message.keyId,
                                                message.keyRemoteNcId,
                                                message.data,
                                                message.mediaURL,
                                                message.imageThumbnail,
                                                message.date
                                            ).then(() => {
                                                this.meetingSvc.meetingCreated();
                                                return message;
                                            });
                                            break;
                                        default:
                                        // we might have received a different control message ..ignore for now
                                    }
                                    next = next.then(() => {
                                        this.groupMetadataChangedSubject.next(message.keyRemoteNcId);
                                        return message;
                                    });
                                }
                                return fromPromise(next);
                            })
                        );
                }
                if ( message.status === SQLiteMessageStatus.ControlMessage ) {
                    // this is a control message, so we don't really want to add it to a chat, right now this control messages are talking
                    // about a user that has changed their metadata..so I think we are good just retrieving the metadata
                    return this.contactManager.fetchSingleContactUpdate(message.keyRemoteNcId).pipe(
                        map(() => {
                            return null;
                        })
                    );
                }
                return of(message);
            }),
            switchMap((message) => {
                if ( !message ) {
                    return of(null);
                }
                return this.addMessage(message);
            })
        );
    }

    private handleProfilePictureUpload(messageToBeSent: SQLiteMessage) {
        return fromPromise(this.file.resolveDirectoryUrl(this.file.dataDirectory))
            .pipe(
                switchMap(directoryEntry => {
                    return fromPromise(this.file.getFile(directoryEntry, messageToBeSent.mediaName, {}));
                }),
                switchMap(fileEntry => fromPromise(this.readFileAsDataURL(fileEntry))),
                switchMap(dataURLFileContent => {
                    return this.http.post<{ error?: boolean, errorDescription?: string, success?: true, imageURL: string }>(
                        'http://68.183.38.142/imageUpload',
                        { file: dataURLFileContent }
                    );
                }),
                switchMap(response => {
                    if ( response.error ) {
                        throw new Error(response.errorDescription);
                    }
                    const updatedMessage = { ...messageToBeSent, uploadedMedia: true, data: response.imageURL };
                    // we should update the metadata as it should hold the information about the group chat
                    return fromPromise(this.dataStorage.updateMessage(updatedMessage)
                        .then(() => {
                            return updatedMessage;
                        })
                    );
                }),
            );
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    getSystemChatMessages(): Observable<SQLiteMessage[]> {
        return fromPromise(this.dataStorage.getSystemMessages())
            .pipe(
                map(systemMessages => {
                    return systemMessages.map(systemMessage => ({
                            data:          systemMessage.message,
                            keyRemoteNcId: 'system',
                            keyFromMe:     SQLiteMessageKeyFromMe.Incoming,
                            status:        SQLiteMessageStatus.Received,
                        })
                    );
                })
            );
    }
}

export const ImageTypes = {
    'image/gif':  'gif',
    'image/jpeg': 'jpg',
    'image/png':  'png',
};

export function base64MimeType(encoded) {
    let result = null;

    if ( typeof encoded !== 'string' ) {
        return result;
    }

    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if ( mime && mime.length ) {
        result = mime[1];
    }

    return result;
}

export function dataURIToBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for ( let i = 0; i < byteString.length; i++ ) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
}
