import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';
import { NavController } from '@ionic/angular';
import { Preference } from '../../../models/Preference';

@Component({
    selector:    'app-select-tag-header',
    templateUrl: './select-tag-header.component.html',
    styleUrls:   ['./select-tag-header.component.scss']
})
export class SelectTagHeaderComponent {
    @Input() isEdit: boolean;
    @Input() displayProperty = 'name';
    @Input() title: string;
    @Input() selectedTags: Preference[];
    searchControl: FormControl = new FormControl();
    @Output() searchPerformed = this.searchControl.valueChanges;
    @Output() onTagSelected = new EventEmitter<Preference>();

    constructor(
        private router: Router,
        private navCtrl: NavController,
        private nativePageTransitions: NativePageTransitions
    ) {
    }

    selectTag(selectedTag: Preference) {
        this.onTagSelected.emit(selectedTag);
    }


}
