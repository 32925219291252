import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cloodsCentsToNumber'
})
export class CloodsCentsToNumberPipe implements PipeTransform {
    transform(cents: string) {
        const currentAmount = Number.parseInt(cents, 10);
        return 'C' + (currentAmount / 100).toFixed(2);
    }

}
