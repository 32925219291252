import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Allergy } from '../../models/allergy';
import { Dietary } from '../../models/dietary';

@Injectable({
    providedIn: 'root'
})
export class PreferencesService {

    allergies: Allergy[] = [
        {
            id:       1,
            name:     'peanuts',
            category: ''
        },
        {
            id:       2,
            name:     'gluten',
            category: ''
        },
        {
            id:       3,
            name:     'lactose',
            category: ''
        },
        {
            id:       4,
            name:     'shellfish',
            category: ''
        },
        {
            id:       19,
            name:     'ketogenic',
            category: ''
        },

        {
            id:       22,
            name:     'vegetarian',
            category: ''
        },
        {
            id:       23,
            name:     'pescatarian',
            category: ''
        },
        {
            id:       24,
            name:     'meditarranean',
            category: ''
        },
        {
            id:       25,
            name:     'raw meat',
            category: ''
        },
        {
            id:       26,
            name:     'ketogenic',
            category: ''
        }

    ];
    dietaries: Dietary[] = [
        {
            id:       1,
            name:     'vegan',
            category: ''
        },
        {
            id:       2,
            name:     'vegetarian',
            category: ''
        },
        {
            id:       3,
            name:     'pescatarian',
            category: ''
        },
        {
            id:       4,
            name:     'meditarranean',
            category: ''
        },
        {
            id:       5,
            name:     'raw meat',
            category: ''
        },
        {
            id:       6,
            name:     'ketogenic',
            category: ''
        }

    ];
    illness: Dietary[] = [
        {
            id:       1,
            name:     'down syndrome',
            category: ''
        },
        {
            id:       2,
            name:     'autism',
            category: ''
        },
        {
            id:       3,
            name:     'epilepsy',
            category: ''
        },
        {
            id:       4,
            name:     'spinal bifida',
            category: ''
        },
        {
            id:       5,
            name:     'depression',
            category: ''
        },
        {
            id:       6,
            name:     'anorexia nervosa',
            category: ''
        }

    ];

    constructor() {
    }

    getAllergies() {
        return of(this.allergies);
    }

    getDietaries() {
        return of(this.dietaries);
    }

    getIlnesses() {
        return of(this.illness);
    }

}
