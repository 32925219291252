import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(items: any[], property: string, terms: string): any[] {
        if ( !items ) {
            return [];
        }
        if ( !terms ) {
            return items;
        }
        terms = terms.toLowerCase();
        return items.filter(it => {
            const prop = this.resolve(property, it);
            return prop && it[property].toLowerCase().includes(terms);
        });
    }

    private resolve(path, obj, separator = '.') {
        const properties = Array.isArray(path) ? path : path.split(separator);
        return properties.reduce((prev, curr) => prev && prev[curr], obj);
    }
}
