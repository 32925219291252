import { NgModule } from '@angular/core';
import { CapitalizeFirstPipe } from './capitalize-first.pipe';
import { ChatPipe } from './chat/chat.pipe';
import { CloodsCentsToNumberPipe } from './cloods-cents-to-number.pipe';
import { PreferencesPipe } from './preferences.pipe';
import { ReversePipe } from './reverse.pipe';
import { SearchPipe } from './search.pipe';
import { SearchPipeArray } from './searchArrayString.pipe';
import { TruncatePipe } from './truncate.pipe';
import { UserNamesPipe } from './userNames/user-names.pipe';
import { MillifyPipe } from './millify.pipe';


const pipes = [
    ChatPipe,
    CapitalizeFirstPipe,
    SearchPipe,
    PreferencesPipe,
    UserNamesPipe,
    ReversePipe,
    SearchPipeArray,
    CloodsCentsToNumberPipe,
    TruncatePipe,
    MillifyPipe,
];


@NgModule({
    declarations: [...pipes],
    imports:      [],
    exports:      [...pipes]
})
export class PipesModule {
}
