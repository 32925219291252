import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { fromPromise } from 'rxjs/internal-compatibility';
import { switchMap } from 'rxjs/operators';
import { TokenInterceptor } from '../../interceptors/token.interceptor';
import { topicNameFromNcId } from '../../shared/utility/topicNameFromNcId';
import { ApiService } from '../api/api.service';
import { DataStorageService } from '../data-storage/data-storage.service';
import { PushNotificationsService } from '../notifications/push-notifications.service';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'my-auth-token'
        })
    };

    constructor(
        private  http: HttpClient,
        private apiService: ApiService,
        private notificationSvc: PushNotificationsService,
        private dataSvc: DataStorageService,
        private platform: Platform
    ) {
    }


    signup(user) {
        return this.apiService.signup(user);
    }

    signin(phone: string, password: string) {
        return this.apiService.signin(phone, password)
            .pipe(
                switchMap(({ status, token }) => {
                    return this.afterTokenReceived(token);
                })
            );
    }

    fakeSignin(phone: string) {
        return this.apiService.fakeSignin(phone).pipe(
            switchMap(({ status, token }) => {
                    return this.afterTokenReceived(token);
                }
            )
        );
    }

    isAuthenticated() {
        return !!TokenInterceptor.getToken();
    }

    private getTopicName() {
        return (this.platform.is('ios') ? '' : '/topics') + topicNameFromNcId(TokenInterceptor.getCurrentUser().ncId);
    }

    private afterTokenReceived(token) {
        TokenInterceptor.setToken(token);
        this.notificationSvc.subscribeChannel(this.getTopicName());
        // we have to pull the profile for this guy..now that we know we are just online
        return this.apiService.getUserProfile().pipe(
            switchMap(userMetadata => {
                return fromPromise(this.dataSvc.updateUserMetadata(userMetadata));
            })
        );
    }

    verify(phone: any) {
        return this.apiService.verifyPhone(phone);
    }
}
