import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TermsAndConditionsPage } from './terms-and-conditions.page';

const routes: Routes = [
    {
        path:      '',
        component: TermsAndConditionsPage
    }
];

@NgModule({
    imports:      [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    declarations: [TermsAndConditionsPage]
})
export class TermsAndConditionsPageModule {
}
