export const havePreferencesChanged = (originalValue, _selectedTags): any => {
    const remainingOriginal = [...originalValue];
    const remainingSelectedTags = _selectedTags.reduce((remainder, item) => {
        const foundIndex = remainingOriginal.findIndex(x => x.id === item.id);
        if ( foundIndex > -1 ) {
            remainingOriginal.splice(foundIndex, 1);
            return remainder;
        }
        remainder.push(item);
        return remainder;
    }, []);
    return !!(remainingOriginal.length || remainingSelectedTags.length);
};
