import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { CameraMock } from './mocks/CameraMock';
import { ContactsMock } from './mocks/ContactsMock';
import { FileMock } from './mocks/FileMock';
import { NetworkMock } from './mocks/NetworkMock';
import { SQLiteMock, SQLitePorterMock } from './mocks/SQLiteMock';
import { AuthGuardService } from './providers/guards/auth-guard.service';
import { PushNotificationsService } from './providers/notifications/push-notifications.service';
import { ComponentsModule } from './shared/components/components.module';
import { SocketIoModule } from './shared/modules';
import { PipesModule } from './shared/pipes/pipes.module';
import { TermsAndConditionsPageModule } from './terms-and-conditions/terms-and-conditions.module';
import { TermsAndConditionsPage } from './terms-and-conditions/terms-and-conditions.page';


declare var SQL;

export function SQLiteFactory() {
    return provideMockOrReal(SQLite, SQLiteMock);
}

export function SQLitePorterFactory() {
    return provideMockOrReal(SQLitePorter, SQLitePorterMock);
}

export function ContactsFactory() {
    return provideMockOrReal(Contacts, ContactsMock);
}

export function NetworkFactory() {
    return provideMockOrReal(Network, NetworkMock);
}

export function FileFactory() {
    return provideMockOrReal(File, FileMock);
}

export function CameraFactory() {
    return provideMockOrReal(Camera, CameraMock);
}


export function provideMockOrReal(real, mock) {
    if ( window.cordova && window.cordova.platformId !== 'browser' ) {
        return new real();
    }
    return new mock();
}

@NgModule(
    {
        declarations:    [AppComponent],
        imports:         [
            BrowserModule,
            IonicModule.forRoot(),
            AppRoutingModule,
            ComponentsModule,
            PipesModule,
            HttpClientModule,
            TermsAndConditionsPageModule,
            IonicStorageModule.forRoot(),
            SocketIoModule.forRoot({ uri: 'ws://68.183.38.142', options: { autoConnect: false, transports: ['websocket'], port: '3001' } }),
            MomentModule,
        ],
        providers:       [
            AuthGuardService,
            PushNotificationsService,
            FirebaseMessaging,
            AppVersion,
            { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
            SplashScreen,
            StatusBar,
            { provide: Camera, useFactory: CameraFactory },
            { provide: File, useFactory: FileFactory },
            WebView,
            { provide: SQLite, useFactory: SQLiteFactory },
            { provide: SQLitePorter, useFactory: SQLitePorterFactory },
            { provide: Contacts, useFactory: ContactsFactory },
            { provide: Network, useFactory: NetworkFactory },
            SocialSharing

        ],
        bootstrap:       [AppComponent],
        entryComponents: [TermsAndConditionsPage]

    }
)
export class AppModule {
}
