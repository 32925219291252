import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from '../../../providers/authentication/authentication.service';

export const VERIFY_SMS_COMPONENT_SUCCESS = 'VERIFY_SMS_COMPONENT_SUCCESS';

@Component({
    selector:    'app-verify-sms',
    templateUrl: './verify-sms.component.html',
    styleUrls:   ['./verify-sms.component.scss'],
})
export class VerifySmsComponent implements OnInit {
    phone;
    code: any;
    errorVerify: any;
    showRetrySend = false;

    constructor(public modalSvc: ModalController, public authenticationSvc: AuthenticationService) {
    }

    ngOnInit() {
    }

    verify() {
        debugger;
        this.errorVerify = null;
        this.authenticationSvc.signin(this.phone, this.code).subscribe(() => {
            this.modalSvc.dismiss(null, VERIFY_SMS_COMPONENT_SUCCESS);
        }, error => {
            this.errorVerify = 'Failed to verify phone';
            this.showRetrySend = true;
        });
    }

    retry() {
        this.authenticationSvc.verify(this.phone).subscribe(() => {
        });
    }
}
