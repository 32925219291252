import { Component, OnInit } from '@angular/core';
import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { ContactManagerService } from '../../providers/contact-manager/contact-manager.service';

@Component({
    selector:    'app-sync-contacts',
    templateUrl: './sync-contacts.component.html',
    styleUrls:   ['./sync-contacts.component.scss'],
})
export class SyncContactsComponent implements OnInit {

    constructor(
        private modalCtrl: ModalController,
        private loadingCtrl: LoadingController,
        private contactManager: ContactManagerService,
        private alertCtrl: AlertController,
        private navCtrl: NavController,
        private nativePageTransitions: NativePageTransitions
    ) {

    }

    ngOnInit() {
    }

    presentLoading() {
        return this.loadingCtrl.create({
            message: 'Syncing contacts...'
        }).then(loading => {
            return loading.present();
        });
    }

    onDismiss() {
        this.navCtrl.navigateRoot('');
        this.modalCtrl.dismiss();
    }

    syncContacts() {
        this.presentLoading().then(() => {
            this.contactManager.fetchContactUpdates().pipe(take(1)).subscribe(() => {
                this.loadingCtrl.dismiss();
                this.onDismiss();
            }, (error) => {
                this.alertCtrl.create({
                    header:  'Failed to sync contacts',
                    buttons: [
                        {
                            text:    'Try again',
                            handler: () => {
                                this.syncContacts();
                            }
                        },
                        {
                            text:    'Cancel',
                            role:    'cancel',
                            handler: () => {
                            }
                        }
                    ]
                }).then(alert => {
                    alert.present();
                });
                return;
            });
        });
    }

}
