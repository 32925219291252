import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';
import { IonicModule } from '@ionic/angular';
import { SyncContactsComponent } from '../../pages/sync-contacts/sync-contacts.component';
import { ContainsObjectPipe } from '../pipes/contains-object.pipe';
import { PipesModule } from '../pipes/pipes.module';
import { AddGroupUserComponent } from './add-group-user/add-group-user.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { GroupPreferencesListComponent } from './group-preferences-list/group-preferences-list.component';
import { InviteFriendComponent } from './invite-friend/invite-friend.component';
import { NewGroupModalPage } from './new-group-modal/new-group-modal.page';
import { PreferencesListComponent } from './preferences-list/preferences-list.component';
import { SelectTagHeaderComponent } from './select-tag-header/select-tag-header.component';
import { SelectTagModalComponent } from './select-tag-modal/select-tag-modal.component';
// import { PreferencesListComponent } from './preferences-list/preferences-list.component';
import { SelectTagComponent } from './select-tag/select-tag.component';
import { VerifySmsComponent } from './verify-sms/verify-sms.component';
import {DirectivesModule} from '../directives/directives.module';


const components = [
    GoogleMapComponent,
    SelectTagComponent,
    ContainsObjectPipe,
    NewGroupModalPage,
    PreferencesListComponent,
    SelectTagModalComponent,
    SelectTagHeaderComponent,
    SyncContactsComponent,
    GroupPreferencesListComponent,
    InviteFriendComponent,
    AddGroupUserComponent,
    VerifySmsComponent
];


@NgModule({
    declarations:    components,
    imports:         [
        PipesModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        DirectivesModule,

    ],
    entryComponents: [
        NewGroupModalPage,
        SelectTagModalComponent,
        SyncContactsComponent,
        InviteFriendComponent,
        AddGroupUserComponent,
        VerifySmsComponent,
    ],
    exports:         [
        ...components,
        DirectivesModule,
        PipesModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
    ],
    providers:       [NativePageTransitions]
})
export class ComponentsModule {
}
