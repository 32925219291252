export const generateFromImage = (imageURI, MAX_WIDTH: number = 700, MAX_HEIGHT: number = 700, quality: number = 1): Promise<string> => {
    return new Promise((resolve, reject) => {
        const canvas: any = document.createElement('canvas');
        const image = new Image();

        image.onload = () => {
            let width = image.width;
            let height = image.height;

            if ( width > height ) {
                if ( width > MAX_WIDTH ) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if ( height > MAX_HEIGHT ) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(image, 0, 0, width, height);

            // IMPORTANT: 'jpeg' NOT 'jpg'
            const dataUrl = canvas.toDataURL('image/jpeg', quality);
            resolve(dataUrl);
        };
        image.src = imageURI;
    });
};
