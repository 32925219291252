export const contactsMockedData = [
    {

        'id':            '196',
        'rawId':         null,
        'displayName':   'maite navarro',
        'name':          null,
        'nickname':      null,
        'phoneNumbers':  [
            {
                'id':    '1065',
                'pref':  false,
                'value': '+34 675670674',
                'type':  'mobile'
            }
        ],
        'emails':        null,
        'addresses':     null,
        'ims':           null,
        'organizations': null,
        'birthday':      null,
        'note':          null,
        'photos':        null,
        'categories':    null,
        'urls':          null

    },
    {

        'id':            '205',
        'rawId':         null,
        'displayName':   'Xavi Gonzalez',
        'name':          null,
        'nickname':      null,
        'phoneNumbers':  [
            {
                'id':    '56',
                'pref':  false,
                'value': '+34 655483493',
                'type':  'mobile'
            }
        ],
        'emails':        null,
        'addresses':     null,
        'ims':           null,
        'organizations': null,
        'birthday':      null,
        'note':          null,
        'photos':        null,
        'categories':    null,
        'urls':          null

    },
    {

        'id':            '206',
        'rawId':         null,
        'displayName':   'Chris Diplock',
        'name':          null,
        'nickname':      null,
        'phoneNumbers':  [
            {
                'id':    '57',
                'pref':  false,
                'value': '+44 34678676754',
                'type':  'mobile'
            }
        ],
        'emails':        null,
        'addresses':     null,
        'ims':           null,
        'organizations': null,
        'birthday':      null,
        'note':          null,
        'photos':        null,
        'categories':    null,
        'urls':          null

    },

    {

        'id':            '278',
        'rawId':         null,
        'displayName':   'Charlie Freeman',
        'name':          null,
        'nickname':      null,
        'phoneNumbers':  [
            {
                'id':    '355',
                'pref':  false,
                'value': '+44 73578676754',
                'type':  'mobile'
            }
        ],
        'emails':        null,
        'addresses':     null,
        'ims':           null,
        'organizations': null,
        'birthday':      null,
        'note':          null,
        'photos':        null,
        'categories':    null,
        'urls':          null

    },

    {

        'id':            '465',
        'rawId':         null,
        'displayName':   'Denis Law',
        'name':          null,
        'nickname':      null,
        'phoneNumbers':  [
            {
                'id':    '375',
                'pref':  false,
                'value': '+44 73563876754',
                'type':  'mobile'
            }
        ],
        'emails':        null,
        'addresses':     null,
        'ims':           null,
        'organizations': null,
        'birthday':      null,
        'note':          null,
        'photos':        null,
        'categories':    null,
        'urls':          null

    },

    {

        'id':            '435',
        'rawId':         null,
        'displayName':   'Jess Treanor',
        'name':          null,
        'nickname':      null,
        'phoneNumbers':  [
            {
                'id':    '215',
                'pref':  false,
                'value': '+44 73574376754',
                'type':  'mobile'
            }
        ],
        'emails':        null,
        'addresses':     null,
        'ims':           null,
        'organizations': null,
        'birthday':      null,
        'note':          null,
        'photos':        null,
        'categories':    null,
        'urls':          null

    },
];
