import { Pipe, PipeTransform } from '@angular/core';
import { Chat } from '../../../models/Chat';

@Pipe({
    name: 'userNames'
})
export class UserNamesPipe implements PipeTransform {

    transform(chat: Chat, currentUserId?: number): string {
        var userNames;
        if ( chat.participants ) {
            userNames = chat.participants.filter((users) => users.id !== currentUserId).map((u) => u.user.profileName);
            if ( userNames.length > 1 ) {
                userNames.join(', ');
            } else {
                userNames.toString();
            }
        }
        return userNames;
    }

}
