import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { share, take } from 'rxjs/operators';
import * as io from 'socket.io-client';
import { SocketIoConfig } from './interfaces/socket-io.config';

export const SOCKET_CONFIG_TOKEN = new InjectionToken<SocketIoConfig>('__SOCKET_IO_CONFIG__');
export const SocketFactory = (config: SocketIoConfig) => {
    return new WrappedSocket(config);
};


@Injectable()
export class WrappedSocket {

    subscribersCounter: { [id: string]: number } = {};
    ioSocket: any;
    emptyConfig: SocketIoConfig;

    constructor(@Inject(SOCKET_CONFIG_TOKEN) config: SocketIoConfig) {
        this.emptyConfig = {
            uri:     '',
            options: {}
        };
        if ( config === undefined ) {
            config = this.emptyConfig;
        }
        const uri = config.uri;
        const options = config.options;
        this.ioSocket = io(uri, options);
    }

    of(namespace: string): void {
        this.ioSocket.of(namespace);
    }

    on(eventName: string, callback: Function): void {
        this.ioSocket.on(eventName, callback);
    }

    once(eventName: string, callback: Function): void {
        this.ioSocket.once(eventName, callback);
    }

    connect(): any {
        return this.ioSocket.connect();
    }

    disconnect(close?: any): any {
        return this.ioSocket.disconnect.apply(this.ioSocket, arguments);
    }

    emit(eventName: string, data?: any, callback?: Function): any {
        return this.ioSocket.emit.apply(this.ioSocket, arguments);
    }

    removeListener(eventName: string, callback?: Function): any {
        return this.ioSocket.removeListener.apply(this.ioSocket, arguments);
    }

    removeAllListeners(eventName?: string): any {
        return this.ioSocket.removeAllListeners.apply(this.ioSocket, arguments);
    }

    fromEvent<T>(eventName: string): Observable<{ data: T, callback?: (response: any) => string }> {
        return new Observable<{ data: T, callback?: (response: any) => string }>((observer) => {
            this.ioSocket.on(eventName, (data, callback) => {
                observer.next({ data: data, callback });
            });
            if ( !this.subscribersCounter[eventName] ) {
                this.subscribersCounter[eventName] = 0;
            }

            this.subscribersCounter[eventName]++;

            return () => {
                if ( this.subscribersCounter[eventName] === 1 ) {
                    this.ioSocket.removeListener(eventName);
                }
                this.subscribersCounter[eventName]--;
            };
        }).pipe(share());
    }

    fromOneTimeEvent<T>(eventName: string): Promise<T> {
        return Observable.create((observer) => {
            this.ioSocket.on(eventName, (data, callback) => {
                observer.next({ data, callback });
            });
            if ( !this.subscribersCounter[eventName] ) {
                this.subscribersCounter[eventName] = 0;
            }

            this.subscribersCounter[eventName]++;

            return () => {
                if ( this.subscribersCounter[eventName] === 1 ) {
                    this.ioSocket.removeListener(eventName);
                }
                this.subscribersCounter[eventName]--;
            };
        }).pipe(take(1));
    }
}
