import { Injectable } from '@angular/core';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Platform } from '@ionic/angular';
import { merge } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationManagerService } from '../communication-manager/communication-manager.service';

@Injectable()
export class PushNotificationsService {
    constructor(
        private firebase: FirebaseMessaging,
        private platform: Platform,
        private communicationManager: CommunicationManagerService
    ) {

    }

    async getToken() {
        let token;

        if ( this.platform.is('android') ) {
            token = await this.firebase.getToken();
        }

        if ( this.platform.is('ios') ) {
            token = await this.firebase.getToken();
            await this.firebase.requestPermission();
        }

        this.saveToken(token);
    }

    onNotifications() {
        return merge(this.firebase.onMessage(), this.firebase.onBackgroundMessage());
    }

    subscribeChannel(channelId) {
        return this.firebase.subscribe(channelId);
    }

    unsubscribeChannel(channelId) {
        return this.firebase.unsubscribe(channelId);
    }

    private saveToken(token) {
        if ( !token ) {
            return;
        }

        this.communicationManager.sendEvent('registerNotificationToken', { token: token }, true).pipe(
            take(1),
        ).subscribe();
    }


}
