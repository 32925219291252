import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ChatManagerService } from '../../../providers/chat-manager/chat-manager.service';
import { ContactManagerService } from '../../../providers/contact-manager/contact-manager.service';
import { getNameFromNCloodUser } from '../../utility/getNameFromNCloodUser';
import { GroupParticipant } from '../new-group-modal/new-group-modal.page';

@Component({
    selector:    'app-add-group-user',
    templateUrl: './add-group-user.component.html',
    styleUrls:   ['./add-group-user.component.scss'],
})
export class AddGroupUserComponent implements OnInit {
    contacts$: Observable<GroupParticipant[]>;
    public toAddUserIds: { [ncId: string]: boolean } = {};
    terms: any;
    private ncIdSubject$ = new BehaviorSubject(null);

    constructor(
        contactSvc: ContactManagerService,
        chatManager: ChatManagerService,
        private modalController: ModalController,
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
        private contactManager: ContactManagerService,
    ) {
        this.contacts$ = this.ncIdSubject$.pipe(
            filter(x => !!x),
            switchMap(ncId => {
                return combineLatest([
                    chatManager.getGroupChatMetaData(ncId),
                    contactSvc.getContacts()
                ]);
            }),
            map(([groupChat, contacts]) => {
                return contacts
                    .filter(contact => groupChat.participants.findIndex(x => x.user_id === contact.ncId) === -1);
            }),
            map(actualUsers => {
                this.toAddUserIds = actualUsers.reduce((acc, nCloodUser) => {
                    acc[nCloodUser.ncId] = false;
                    return acc;
                }, {});
                return actualUsers.map(nCloodUser => {
                    return {
                        name:  getNameFromNCloodUser(nCloodUser),
                        photo: nCloodUser.metadata && nCloodUser.metadata.profileThumbnailPicture,
                        ncId:  nCloodUser.ncId
                    };
                });
            })
        );

        this.checkObjectLength(this.toAddUserIds);
    }

    @Input() set ncId(value) {
        this.ncIdSubject$.next(value);
    }

    ngOnInit() {
    }

    dismissModal() {
        this.modalController.dismiss();
    }

    onCreate() {
        this.modalController.dismiss({ toAddUserIds: Object.keys(this.toAddUserIds).filter(key => this.toAddUserIds[key]) }, 'confirm');
    }

    checkObjectLength(obj) {
        if ( !obj ) {
            return 0;
        }
        console.log(Object.keys(this.toAddUserIds).filter(key => this.toAddUserIds[key]).length);
        return Object.keys(this.toAddUserIds).filter(key => this.toAddUserIds[key]).length;
    }


    async presentLoading() {
        const loading = await this.loadingCtrl.create({
            message: 'Syncing contacts...'
        });
        await loading.present();
    }

    syncContacts() {
        this.presentLoading().then(() => {
            this.contactManager.fetchContactUpdates().pipe(take(1)).subscribe(() => {
                this.loadingCtrl.dismiss();
            }, (error) => {
                this.loadingCtrl.dismiss();
                this.alertCtrl.create({
                    header:  'Failed to sync contacts',
                    buttons: [
                        {
                            text:    'Try again',
                            handler: () => {
                                this.syncContacts();
                            }
                        },
                        {
                            text:    'Cancel',
                            role:    'cancel',
                            handler: () => {
                            }
                        }
                    ]
                }).then(alert => {
                    alert.present();

                });
                return;
            });
        });

    }
}
