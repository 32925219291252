import { Pipe, PipeTransform } from '@angular/core';

const units = [
  '',
  'K', // Thousand
  'M', // Million
  'B', // Billion
  'T', // Trillion
  'P', // Quadrillion
  'E', // Quintillion
];


@Pipe({
  name: 'millify'
})
export class MillifyPipe implements PipeTransform {

  transform(value: number, args: any[]): string {
    if (value === null) {
      return 'Not assigned';
    }

    const prefix = value < 0 ? '-' : '';
    let index = 0;

    while (value / 1000 > 1) {
      value = value / 1000;
      index += 1;
    }
    return `${prefix}${value} ${units[index]}`;
  }
}
