import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Charity } from '../../models/Charity';
import { CloodRequest } from '../../models/CloodRequest';
import { PreferenceCollection } from '../../models/Collections/PreferenceCollection';
import { Invitation } from '../../models/Invitation';
import { ITransaction } from '../../pages/cloods/cloods.page';
import { DonationSubscriptionCreation } from '../../pages/donate/donate-options/donate-options.page';
import { UserMetaData } from '../communication-manager/communication-manager.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    APIURL = environment.apiURL;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private  http: HttpClient) {
    }

    getUserProfile() {
        return this.http.get<UserMetaData>(`${this.APIURL}/me`);
    }


    signup(user): Observable<{ status: string, token: string }> {
        return this.http.post<{ status: string, token: string }>(`${this.APIURL}/signUp`, user);
    }

    signin(phone: string, password: string) {
        return this.http.post<{ status, token }>(`${this.APIURL}/login`, { phone, password });
    }


    getAllergies(params): Observable<PreferenceCollection> {
        return this.http.get<PreferenceCollection>(`${this.APIURL}/allergies`, {
            params
        });
    }

    getDietaryPreferences(params): Observable<PreferenceCollection> {
        return this.http.get<PreferenceCollection>(`${this.APIURL}/dietaryPreferences`, {
            params
        });
    }

    getPhysicalAndMentalSymptom(params): Observable<PreferenceCollection> {
        return this.http.get<PreferenceCollection>(`${this.APIURL}/physicalAndMentalSymptom`, {
            params
        });
    }

    getTemporaryIllnesses(params): Observable<PreferenceCollection> {
        return this.http.get<PreferenceCollection>(`${this.APIURL}/temporaryIllnesses`, {
            params
        });
    }

    getCharityIssueCategories(params): Observable<any> {
        return this.http.get<any>(`${this.APIURL}/charityIssueCategories`, {
            params
        });
    }

    getCharityIssues(params): Observable<any> {
        return this.http.get<any>(`${this.APIURL}/charityIssues`, {
            params
        });
    }

    createTopUp(amount: any) {
        return this.http.post<{ clientSecret: string }>(`${this.APIURL}/me/topUp`, { amount });
    }

    getBalance(): Observable<number> {
        return this.http.get<{ balance: number }>(`${this.APIURL}/me/balance`).pipe(
            map(balanceResult => {
                return balanceResult.balance;
            })
        );
    }


    getTransactions(startDate: Moment = null, endDate: Moment = null, offset: number = null, limit: number = null) {
        const transactionsURL = new URL(`${this.APIURL}/me/transactions`);
        if ( startDate ) {
            transactionsURL.searchParams.append('startDate', startDate.format('YYYY-MM-DD HH:mm:ss'));
        }
        if ( endDate ) {
            transactionsURL.searchParams.append('endDate', endDate.format('YYYY-MM-DD HH:mm:ss'));
        }
        if ( offset !== null ) {
            transactionsURL.searchParams.append('offset', String(offset));
        }
        if ( limit !== null ) {
            transactionsURL.searchParams.append('limit', String(limit));
        }
        return this.http.get<ITransaction[]>(transactionsURL.toString());
    }

    sendCloods(receiver: any, amount: any | PaymentCurrencyAmount, comment: string | any) {
        return this.http.post(`${this.APIURL}/me/sendCloods`, { receiver, amount, comment });
    }

    sendInvite(phone: string, message: string): Observable<{ status: string; invite: Invitation }> {
        return this.http.post<{ status: string; invite: Invitation }>(`${this.APIURL}/me/invitations`, { phone, message });
    }

    getInvites(): Observable<Invitation[]> {
        return this.http.get<Invitation[]>(`${this.APIURL}/me/invitations`);
    }

    getCharities(params: any = null): Observable<Charity[]> {
        return this.http.get<Charity[]>(`${this.APIURL}/charities`, {
            params
        });
    }

    getCharity(charityId: string) {
        return this.http.get<Charity>(`${this.APIURL}/charities/${charityId}`);
    }

    createDonation(donationModel: DonationSubscriptionCreation) {
        const { isSpare, amount, frequency, charity } = donationModel;
        return this.http.post(`${this.APIURL}/me/donationSubscriptions`, { isSpare, amount, frequency, charityId: charity.id });
    }

    verifyPhone(phone: any) {
        return this.http.post(`${this.APIURL}/verify`, { phone });
    }

    createShare(participants: any, narrative: string) {
        return this.http.post(`${this.APIURL}/me/createShare`, { participants, narrative });
    }

    getPendingDebitRequests() {
        return this.http.get<CloodRequest[]>(`${this.APIURL}/me/debitRequests?status=pending`);
    }

    getPendingCreditRequests() {
        return this.http.get<CloodRequest[]>(`${this.APIURL}/me/creditRequests?status=pending`);
    }

    payDebitRequest(cloodRequestId) {
        return this.http.post(`${this.APIURL}/me/debitRequests/${cloodRequestId}/pay`, {});
    }

    fakeSignin(phone: string) {
        return this.http.post<{ status: string, token: string }>(`${this.APIURL}/fakeLogin`, { phone });
    }

    getRefunds() {
        return this.http.get<ITransaction[]>(`${this.APIURL}/me/refunds`);
    }

    processRefund(transactionId: string) {
        return this.http.post(`${this.APIURL}/me/refunds/${transactionId}/process`, {});
    }

    createMeeting(groupId: string, description: any, startDate: any, startTime: any, place: any) {
        console.log(groupId, description, startDate, startTime, place);
    }

    getMeetings(): Observable<Meeting[]> {
        return this.http.get<Meeting[]>(`${this.APIURL}/me/meetings`);
    }

    flagGreetingsFeatureAsVisited() {
        return this.http.post(`${this.APIURL}/me/greetingsFeatureVisited`, null);
    }

    getOccasions() {
        return this.http.get<EventOccasion[]>(`${this.APIURL}/occasions`);
    }

    addEvent(selectedOccasion: EventOccasion, selectedDate: string, selectedYear: number, repeatingEvent: boolean, eventComments: string) {
        const momentDate = moment(selectedDate);
        return this.http.post(
            `${this.APIURL}/me/events`,
            { selectedOccasion, selectedDate: momentDate.format('DD/MM'), selectedYear, repeatingEvent, eventComments }
        );
    }

    getOwnEvents(date: Moment) {
        const eventsURL = new URL(`${this.APIURL}/me/events`);
        eventsURL.searchParams.append('date', date.format('YYYY-MM'));
        return this.http.get<NCloodEvent[]>(eventsURL.toString());
    }

    getContactEvents(date: Moment) {
        const eventsURL = new URL(`${this.APIURL}/me/contactEvents`);
        eventsURL.searchParams.append('date', date.format('YYYY-MM'));
        return this.http.get<NCloodEvent[]>(eventsURL.toString());
    }

    deleteEvent(eventId) {
        return this.http.delete(`${this.APIURL}/me/events/${eventId}`);
    }

    openEnvelope(data: any) {
        return this.http.post(`${this.APIURL}/me/greetingMessageEnvelopes/${data}/open`, {});
    }

    getGreetingOccasionCategories() {
        return this.http.get(`${this.APIURL}/greetingOccasionCategories`);
    }

    getGreetingOccasionSubcategories(greetingOccasionCategoryId: number) {
        return this.http.get(`${this.APIURL}/greetingOccasionCategories/${greetingOccasionCategoryId}/greetingOccasionSubcategories`);
    }

    getGreetingOccasionImages(greetingOccasionCategoryId: number = null, greetingOccasionSubcategoryId: number = null) {

        const params = {};
        if ( greetingOccasionCategoryId ) {
            params['categoryId'] = greetingOccasionCategoryId;
        }
        if ( greetingOccasionSubcategoryId ) {
            params['subcategoryId'] = greetingOccasionSubcategoryId;
        }

        return this.http.get(`${this.APIURL}/greetingOccasionImages`, {
            params
        });
    }

    createGreeting(envelopeText: any, recipients, toArriveDateTime, greetingImageId, cloodGift) {
        return this.http.post(`${this.APIURL}/me/greetingMessages`, {
            recipients,
            toArriveDateTime,
            greetingImageId,
            envelopeText,
            cloodGift
        });
    }
}

export class Meeting {
    id: string;
    groupId: string;
    date: string;
    place: string;
    description: string;
    participants?: string | string[];
}

export interface EventOccasion {
    id: number;
    name: string;
}

export interface NCloodEvent {
    id: number;
    userNcId: string;
    eventOccasionId: number;
    day: number;
    month: number;
    year: number;
    isRepeating: boolean;
    comments: string;
    occasionName: string;
}
