import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Platform, ToastController } from '@ionic/angular';
import { AuthenticationService } from './providers/authentication/authentication.service';
import { PushNotificationsService } from './providers/notifications/push-notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private notificationSvc: PushNotificationsService,
    private toastController: ToastController,
    private authSvc: AuthenticationService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {

    // if ( this.authSvc.isAuthenticated() ) {
    //     this.router.navigateByUrl('tabs');
    // } else {
    //     this.router.navigateByUrl('signin');
    // }
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      if (window.cordova) {
        this.notificationSetup();
      }
    });
  }

  private async presentToast(message) {
    const toast = await this.toastController.create({ message, duration: 3000 });
    await toast.present();
  }

  private notificationSetup() {
    this.notificationSvc.getToken();
    this.notificationSvc.onNotifications().subscribe(
      (msg) => {
        if (msg.gcm) {
          if (this.platform.is('ios')) {
            this.presentToast(msg.gcm.aps.alert);
          } else {
            this.presentToast(msg.gcm.body);
          }
        }
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.platform.ready().then(() => {
        this.splashScreen.hide();
      });
    }, 100);
  }
}
